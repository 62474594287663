import { axiosInstance } from '@config/axios-instance-v2';
import { MarketplaceSettingDto, SettingsDTO } from '@libs/dto';

let url = `/admin/tenant/marketplace/settings/business-model`;

export const marketplaceSettingsService = {
  async getBussinessModel() {
    const response: MarketplaceSettingDto = await axiosInstance.get(`${url}`);
    return response;
  },
  async updateBussinessModel(data: SettingsDTO) {
    const response = await axiosInstance.put(`${url}`, data);
    return response;
  },
};

export type MarketplaceSettingsService = typeof marketplaceSettingsService;
