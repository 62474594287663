export enum PointMutationTypeEnum {
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
}

export enum PointEventEnum {
  EARNED = 'EARNED',
  EXPIRED = 'EXPIRED',
  REDEEMED = 'REDEEMED',
}
