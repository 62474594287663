import AppContextProvider from '@context/app-context';
import AuthContextProvider from '@context/auth-context';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const REACT_APP_SENTRY_ENABLE = process.env.REACT_APP_SENTRY_ENABLE || 'true';

if (REACT_APP_SENTRY_ENABLE === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN || 'https://3f37363b66ea453b95bd30bf27b63b50@sentry.kodingworks.io/11',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['warn', 'error'],
      }),
    ],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'production',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: parseInt(process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE) || 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <AuthContextProvider>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </AuthContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
