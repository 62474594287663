import { axiosInstance } from '@config/axios-instance-v2';
import { ChannelRateDto, ListRoomRateDto, ListRatePlanDto, RatePlanPayloadDto, UpdateOrInsertRateDto } from '@libs/dto';

const url = `/admin/hotels`;

export const rateService = {
  async list(selectedHotelId, date: string) {
    const response: ListRoomRateDto = await axiosInstance.get(`${url}/${selectedHotelId}/rooms/rates`, {
      params: date,
    });
    return response;
  },
  bulkInsertOrUpdate(selectedHotelId: string, data: UpdateOrInsertRateDto) {
    return axiosInstance.post(`${url}/${selectedHotelId}/rooms/rates/bulk-modify`, data);
  },
  async listRatePlan(
    selectedHotelId,
    {
      page = '1',
      per_page = '10',
      name = '',
      breakfast,
    }: { page: string; per_page: string; name: string; breakfast: boolean }
  ) {
    const response: ListRatePlanDto = await axiosInstance.get(`${url}/${selectedHotelId}/rooms/settings/rate-plans`, {
      params: {
        page,
        per_page,
        name,
        breakfast,
      },
    });
    return response;
  },
  async deleteRatePlan(selectedHotelId, id) {
    const response = await axiosInstance.delete(`${url}/${selectedHotelId}/rooms/settings/rate-plans/${id}`);
    return response;
  },
  async addRatePlan(selectedHotelId, data: RatePlanPayloadDto) {
    const response = await axiosInstance.post(`${url}/${selectedHotelId}/rooms/settings/rate-plans`, data);
    return response;
  },
  async validateCodeRatePlan(selectedHotelId, code) {
    const response = await axiosInstance.post(
      `${url}/${selectedHotelId}/rooms/settings/rate-plans/validate-code`,
      code
    );
    return response;
  },
  async getDetailRatePlan(selectedHotelId, id) {
    const response = await axiosInstance.get(`${url}/${selectedHotelId}/rooms/settings/rate-plans/${id}`);
    return response;
  },
  async updateRatePlan(selectedHotelId, id, data: RatePlanPayloadDto) {
    const response = await axiosInstance.put(`${url}/${selectedHotelId}/rooms/settings/rate-plans/${id}`, data);
    return response;
  },
  async getChannelRate(selectedHotelId) {
    const response: ChannelRateDto = await axiosInstance.get(`${url}/${selectedHotelId}/rooms/settings/smart-pricing`);
    return response;
  },
  async updateChannelRate(selectedHotelId, data) {
    const response: ChannelRateDto = await axiosInstance.post(
      `${url}/${selectedHotelId}/rooms/settings/smart-pricing`,
      data
    );
    return response;
  },
};

export type RateService = typeof rateService;
