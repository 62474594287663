import { defineAbility } from '@casl/ability';
import { ActionsEnum, SubjectsEnum } from '@libs/enums';

export default defineAbility((can, _cannot) => {
  /// All
  can(ActionsEnum.MANAGE, SubjectsEnum.ALL);

  /// Hotel
  can(ActionsEnum.READ, SubjectsEnum.HOTEL);
  can(ActionsEnum.CREATE, SubjectsEnum.HOTEL);
  can(ActionsEnum.UPDATE, SubjectsEnum.HOTEL);
  can(ActionsEnum.DELETE, SubjectsEnum.HOTEL);

  /// Hotel Dashboard
  can(ActionsEnum.READ, SubjectsEnum.HOTEL_DASHBOARD);

  /// Room Dashboard
  can(ActionsEnum.READ, SubjectsEnum.ROOM_DASHBOARD);

  /// Room Order
  can(ActionsEnum.READ, SubjectsEnum.ROOM_ORDER);
  can(ActionsEnum.UPDATE, SubjectsEnum.ROOM_ORDER);

  /// Room Availability
  can(ActionsEnum.READ, SubjectsEnum.ROOM_AVAILABILITY);
  can(ActionsEnum.UPDATE, SubjectsEnum.ROOM_AVAILABILITY);

  /// Room Rate
  can(ActionsEnum.READ, SubjectsEnum.ROOM_RATE);
  can(ActionsEnum.UPDATE, SubjectsEnum.ROOM_RATE);

  /// Room
  can(ActionsEnum.READ, SubjectsEnum.ROOM);
  can(ActionsEnum.CREATE, SubjectsEnum.ROOM);
  can(ActionsEnum.UPDATE, SubjectsEnum.ROOM);
  can(ActionsEnum.DELETE, SubjectsEnum.ROOM);

  /// Food Dashboard
  can(ActionsEnum.READ, SubjectsEnum.FOOD_DASHBOARD);

  /// Food Order
  can(ActionsEnum.READ, SubjectsEnum.FOOD_ORDER);
  can(ActionsEnum.UPDATE, SubjectsEnum.FOOD_ORDER);

  /// Food
  can(ActionsEnum.READ, SubjectsEnum.FOOD);
  can(ActionsEnum.CREATE, SubjectsEnum.FOOD);
  can(ActionsEnum.UPDATE, SubjectsEnum.FOOD);
  can(ActionsEnum.DELETE, SubjectsEnum.FOOD);

  /// Food Payment
  can(ActionsEnum.READ, SubjectsEnum.FOOD_PAYMENT);
  can(ActionsEnum.UPDATE, SubjectsEnum.FOOD_PAYMENT);

  /// Souvenir Dashboard
  can(ActionsEnum.READ, SubjectsEnum.SOUVENIR_DASHBOARD);

  /// Souvenir Order
  can(ActionsEnum.READ, SubjectsEnum.SOUVENIR_ORDER);
  can(ActionsEnum.UPDATE, SubjectsEnum.SOUVENIR_ORDER);

  /// Souvenir
  can(ActionsEnum.READ, SubjectsEnum.SOUVENIR);
  can(ActionsEnum.CREATE, SubjectsEnum.SOUVENIR);
  can(ActionsEnum.UPDATE, SubjectsEnum.SOUVENIR);
  can(ActionsEnum.DELETE, SubjectsEnum.SOUVENIR);

  /// Souvenir Payment
  can(ActionsEnum.READ, SubjectsEnum.SOUVENIR_PAYMENT);
  can(ActionsEnum.UPDATE, SubjectsEnum.SOUVENIR_PAYMENT);

  /// Guest
  can(ActionsEnum.READ, SubjectsEnum.GUEST);

  /// Point Gift
  can(ActionsEnum.CREATE, SubjectsEnum.POINT_GIFT);

  /// Point History
  can(ActionsEnum.READ, SubjectsEnum.POINT_HISTORY);

  /// Membership
  can(ActionsEnum.READ, SubjectsEnum.MEMBERSHIP);
  can(ActionsEnum.CREATE, SubjectsEnum.MEMBERSHIP);
  can(ActionsEnum.UPDATE, SubjectsEnum.MEMBERSHIP);
  can(ActionsEnum.DELETE, SubjectsEnum.MEMBERSHIP);

  /// Promo Code
  can(ActionsEnum.READ, SubjectsEnum.PROMO_CODE);
  can(ActionsEnum.CREATE, SubjectsEnum.PROMO_CODE);
  can(ActionsEnum.UPDATE, SubjectsEnum.PROMO_CODE);
  can(ActionsEnum.DELETE, SubjectsEnum.PROMO_CODE);

  /// Voucher
  can(ActionsEnum.READ, SubjectsEnum.VOUCHER);
  can(ActionsEnum.CREATE, SubjectsEnum.VOUCHER);
  can(ActionsEnum.UPDATE, SubjectsEnum.VOUCHER);
  can(ActionsEnum.DELETE, SubjectsEnum.VOUCHER);

  /// Banner
  can(ActionsEnum.READ, SubjectsEnum.BANNER);
  can(ActionsEnum.CREATE, SubjectsEnum.BANNER);
  can(ActionsEnum.UPDATE, SubjectsEnum.BANNER);
  can(ActionsEnum.DELETE, SubjectsEnum.BANNER);

  /// Referral
  can(ActionsEnum.READ, SubjectsEnum.REFERRAL);
  can(ActionsEnum.UPDATE, SubjectsEnum.REFERRAL);

  /// Activity Log
  can(ActionsEnum.READ, SubjectsEnum.ACTIVITY_LOG);

  /// Balance
  can(ActionsEnum.READ, SubjectsEnum.BALANCE);

  /// Balance Topup
  can(ActionsEnum.READ, SubjectsEnum.BALANCE_TOPUP);
  can(ActionsEnum.CREATE, SubjectsEnum.BALANCE_TOPUP);
  can(ActionsEnum.UPDATE, SubjectsEnum.BALANCE_TOPUP);

  /// Balance Withdraw
  can(ActionsEnum.READ, SubjectsEnum.BALANCE_WITHDRAW);
  can(ActionsEnum.CREATE, SubjectsEnum.BALANCE_WITHDRAW);
  can(ActionsEnum.UPDATE, SubjectsEnum.BALANCE_WITHDRAW);

  /// Business Model
  can(ActionsEnum.READ, SubjectsEnum.BUSINESS_MODEL);
  can(ActionsEnum.CREATE, SubjectsEnum.BUSINESS_MODEL);
  can(ActionsEnum.UPDATE, SubjectsEnum.BUSINESS_MODEL);
  can(ActionsEnum.DELETE, SubjectsEnum.BUSINESS_MODEL);

  /// Bank Account
  can(ActionsEnum.READ, SubjectsEnum.BANK_ACCOUNT);
  can(ActionsEnum.CREATE, SubjectsEnum.BANK_ACCOUNT);
  can(ActionsEnum.UPDATE, SubjectsEnum.BANK_ACCOUNT);
  can(ActionsEnum.DELETE, SubjectsEnum.BANK_ACCOUNT);

  /// CMS
  can(ActionsEnum.READ, SubjectsEnum.CMS);
  can(ActionsEnum.CREATE, SubjectsEnum.CMS);
  can(ActionsEnum.UPDATE, SubjectsEnum.CMS);
  can(ActionsEnum.DELETE, SubjectsEnum.CMS);

  /// Role
  can(ActionsEnum.READ, SubjectsEnum.ROLE);
  can(ActionsEnum.CREATE, SubjectsEnum.ROLE);
  can(ActionsEnum.UPDATE, SubjectsEnum.ROLE);
  can(ActionsEnum.DELETE, SubjectsEnum.ROLE);

  /// City
  can(ActionsEnum.READ, SubjectsEnum.CITY);
  can(ActionsEnum.CREATE, SubjectsEnum.CITY);
  can(ActionsEnum.UPDATE, SubjectsEnum.CITY);
  can(ActionsEnum.DELETE, SubjectsEnum.CITY);

  /// Admin
  can(ActionsEnum.READ, SubjectsEnum.ADMIN);
  can(ActionsEnum.CREATE, SubjectsEnum.ADMIN);
  can(ActionsEnum.UPDATE, SubjectsEnum.ADMIN);
  can(ActionsEnum.DELETE, SubjectsEnum.ADMIN);

  /// Point
  can(ActionsEnum.READ, SubjectsEnum.POINT);
  can(ActionsEnum.UPDATE, SubjectsEnum.POINT);

  /// Payment
  can(ActionsEnum.READ, SubjectsEnum.PAYMENT);
  can(ActionsEnum.UPDATE, SubjectsEnum.PAYMENT);

  /// Payment Gateway
  can(ActionsEnum.READ, SubjectsEnum.PAYMENT_GATEWAY);
  can(ActionsEnum.UPDATE, SubjectsEnum.PAYMENT_GATEWAY);

  /// Payment Channel
  can(ActionsEnum.READ, SubjectsEnum.PAYMENT_CHANNEL);
  can(ActionsEnum.UPDATE, SubjectsEnum.PAYMENT_CHANNEL);

  /// Image
  can(ActionsEnum.READ, SubjectsEnum.IMAGE);
  can(ActionsEnum.CREATE, SubjectsEnum.IMAGE);
  can(ActionsEnum.UPDATE, SubjectsEnum.IMAGE);
  can(ActionsEnum.DELETE, SubjectsEnum.IMAGE);
});
