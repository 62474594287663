import { axiosInstance } from '@config/axios-instance-v2';
import { ListImageDto } from '@libs/dto';

const url = '/admin/settings/images';

export const imageService = {
  async list({ page = 1, per_page = 20 }: { page: number; per_page: number }) {
    const response: ListImageDto = await axiosInstance.get(`${url}`, {
      params: {
        page,
        per_page,
      },
    });
    return response;
  },
  async upload(data: any) {
    const response = await axiosInstance.post(`${url}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  },
  delete(id: string) {
    return axiosInstance.delete(`${url}/${id}`);
  },
};

export type ImageService = typeof imageService;
