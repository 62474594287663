import React from 'react';

export default function MembershipIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: '10px' }}
    >
      <mask id="path-1-inside-1_3654_41076" fill="white">
        <rect x="1" y="2" width="14" height="11" rx="0.5" />
      </mask>
      <rect
        x="1"
        y="2"
        width="14"
        height="11"
        rx="0.5"
        stroke="white"
        strokeWidth="2"
        mask="url(#path-1-inside-1_3654_41076)"
      />
      <rect x="3" y="4.2002" width="8" height="1.1" rx="0.1" fill="white" />
      <rect x="3" y="6.40039" width="5" height="1.1" rx="0.1" fill="white" />
      <rect x="3" y="8.59961" width="9" height="1.1" rx="0.1" fill="white" />
    </svg>
  );
}
