import { axiosInstance } from '@config/axios-instance-v2';
import { SouvenirDetailOrderDto } from '@libs/dto/souvenir-order';

const url = 'admin/hotels';
const baseUrl = 'admin';

export const souvenirOrderService = {
  list(
    selectedHotelId: string,
    {
      page = '1',
      per_page = '10',
      start_date = '',
      end_date = '',
      status = '',
      hotelName = '',
      orderBy = '',
      orderId = '',
      guestName = '',
      guestEmail = '',
    }: {
      page: string;
      per_page: string;
      start_date?: string;
      end_date?: string;
      status?: string;
      hotelName?: string;
      orderBy?: string;
      orderId?: string;
      guestName?: string;
      guestEmail?: string;
    }
  ) {
    if (selectedHotelId) {
      return axiosInstance.get(`${url}/${selectedHotelId}/souvenirs/orders`, {
        params: {
          page,
          per_page,
          start_date,
          end_date,
          status,
          orderBy,
          orderId,
          guestName,
          guestEmail,
        },
      });
    } else {
      return axiosInstance.get(`${baseUrl}/souvenirs/orders`, {
        params: {
          page,
          per_page,
          start_date,
          end_date,
          status,
          hotelName,
          orderBy,
          orderId,
          guestName,
          guestEmail,
        },
      });
    }
  },
  async get(id: string, selectedHotelId: string) {
    if (selectedHotelId) {
      const response: SouvenirDetailOrderDto = await axiosInstance.get(
        `${url}/${selectedHotelId}/souvenirs/orders/${id}`
      );
      return response;
    } else {
      const response: SouvenirDetailOrderDto = await axiosInstance.get(`/admin/souvenirs/orders/${id}`);
      return response;
    }
  },
  async pendingSouvenirOrder(id: string, selectedHotelId: String) {
    if (selectedHotelId) {
      const response = await axiosInstance.put(`${url}/${selectedHotelId}/souvenirs/orders/${id}/status-pending`);
      return response;
    } else {
      const response = await axiosInstance.put(`${baseUrl}/souvenirs/orders/${id}/status-pending`);
      return response;
    }
  },
  async proccessSouvenirOrder(id: string, selectedHotelId: String) {
    if (selectedHotelId) {
      const response = await axiosInstance.put(`${url}/${selectedHotelId}/souvenirs/orders/${id}/status-proccess`);
      return response;
    } else {
      const response = await axiosInstance.put(`${baseUrl}/souvenirs/orders/${id}/status-proccess`);
      return response;
    }
  },
  async onHoldSouvenirOrder(id: string, selectedHotelId: String) {
    if (selectedHotelId) {
      const response = await axiosInstance.put(`${url}/${selectedHotelId}/souvenirs/orders/${id}/status-onhold`);
      return response;
    } else {
      const response = await axiosInstance.put(`${baseUrl}/souvenirs/orders/${id}/status-onhold`);
      return response;
    }
  },
  async cancelSouvenirOrder(id: string, selectedHotelId: String) {
    if (selectedHotelId) {
      const response = await axiosInstance.put(`${url}/${selectedHotelId}/souvenirs/orders/${id}/status-cancel`);
      return response;
    } else {
      const response = await axiosInstance.put(`${baseUrl}/souvenirs/orders/${id}/status-cancel`);
      return response;
    }
  },
  async completeSouvenirOrder(id: string, selectedHotelId: String) {
    if (selectedHotelId) {
      const response = await axiosInstance.put(`${url}/${selectedHotelId}/souvenirs/orders/${id}/status-complete`);
      return response;
    } else {
      const response = axiosInstance.put(`${baseUrl}/souvenirs/orders/${id}/status-complete`);
      return response;
    }
  },
  async refundSouvenirOrder(id: string, selectedHotelId: String) {
    if (selectedHotelId) {
      const response = await axiosInstance.put(`${url}/${selectedHotelId}/souvenirs/orders/${id}/status-refund`);
      return response;
    } else {
      const response = await axiosInstance.put(`${baseUrl}/souvenirs/orders/${id}/status-refund`);
      return response;
    }
  },
  async expiredSouvenirOrder(id: string, selectedHotelId: String) {
    if (selectedHotelId) {
      const response = await axiosInstance.put(`${url}/${selectedHotelId}/souvenirs/orders/${id}/status-expired`);
      return response;
    } else {
      const response = await axiosInstance.put(`${baseUrl}/souvenirs/orders/${id}/status-expired`);
      return response;
    }
  },
};

export type SouvenirOrderService = typeof souvenirOrderService;
