import { axiosInstance } from '@config/axios-instance-v2';
import { BusinessModelDto, ListBalancesDto, ListHotelBalancesDto, ResponseReportBalanceDto } from '@libs/dto';

let url = `/admin/tenant/marketplace/hotels`;
let urlAdmin = `/admin/hotels`;

export const marketplaceBalanceService = {
  async getListHotelBalances({
    startDate,
    endDate,
    page = 1,
    per_page = 10,
  }: {
    startDate: string;
    endDate: string;
    page?: number;
    per_page?: number;
  }) {
    const response: ListBalancesDto = await axiosInstance.get(`${url}`, {
      params: {
        startDate,
        endDate,
        page,
        per_page,
      },
    });
    return response;
  },

  async getListHotelBalancesSuperadmin(
    selectedHotelId,
    {
      startDate,
      endDate,
      page = 1,
      per_page = 10,
    }: { startDate: string; endDate: string; page?: number; per_page?: number }
  ) {
    const response: ListHotelBalancesDto = await axiosInstance.get(`${url}/${selectedHotelId}/balances`, {
      params: {
        startDate,
        endDate,
        page,
        per_page,
      },
    });
    return response;
  },

  async getListHotelBalancesAdminHotel(
    selectedHotelId,
    {
      startDate = '',
      endDate = '',
      page = 1,
      per_page = 10,
    }: { startDate: string; endDate: string; page: number; per_page: number }
  ) {
    const response: ListHotelBalancesDto = await axiosInstance.get(
      `${urlAdmin}/${selectedHotelId}/marketplace/balances`,
      {
        params: {
          startDate,
          endDate,
          page,
          per_page,
        },
      }
    );
    return response;
  },

  async getAvailableBalance(selectedHotelId) {
    const response: ResponseReportBalanceDto = await axiosInstance.get(
      `${urlAdmin}/${selectedHotelId}/marketplace/reports`
    );
    return response;
  },
  async getBusinessModel() {
    const response: BusinessModelDto = await axiosInstance.get(`/admin/hotels/marketplace/business-model`);
    return response;
  },
};

export type MarketplaceBalanceService = typeof marketplaceBalanceService;
