import { axiosInstance } from '@config/axios-instance-v2';
import { ArticleAddDto } from '@libs/dto';

const url = `/admin/cms/articles`;
export const cmsArticleService = {
  list({ page = '1', per_page = '10' }: { page: string; per_page: string }) {
    return axiosInstance.get(`${url}`, {
      params: { page, per_page },
    });
  },
  listCategories({ page = '1', per_page = '10', search = '' }: { page: string; per_page: string; search: string }) {
    return axiosInstance.get(`/admin/cms/categories`, {
      params: { page, per_page, search },
    });
  },
  listTags({ page = '1', per_page = '10', search = '' }: { page: string; per_page: string; search: string }) {
    return axiosInstance.get(`/admin/cms/tags`, {
      params: { page, per_page, search },
    });
  },
  get(id: string) {
    return axiosInstance.get(`${url}/${id}`);
  },
  create(data: ArticleAddDto) {
    return axiosInstance.post(`${url}`, data);
  },
  update(id: string, data: ArticleAddDto) {
    return axiosInstance.put(`${url}/${id}`, data);
  },
  delete(id: string) {
    return axiosInstance.delete(`${url}/${id}`);
  },
};

export type CmsArticleService = typeof cmsArticleService;
