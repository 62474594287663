import { ArrowLeftOutlined } from '@ant-design/icons';
import ContentHelmet from '@components/common/ContentHelmet';
import ContentLayout from '@components/common/ContentLayout/ContentLayout';
import { useApp } from '@context/app-context';
import { useNotif } from '@hooks/use-notif';
import { RatePlanPayloadDto } from '@libs/dto';
import { rateService } from '@services/rate';
import { Button, Col, Divider, Form, Input, Radio, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useSWR from 'swr';
import './Edit.scss';

const { TextArea } = Input;

export default function UpdateRatePlan() {
  const [form] = Form.useForm();
  const { addSuccess, addError } = useNotif();
  const { selectedHotelId } = useApp();
  const history = useHistory();
  const { id: ratePlanId } = useParams<{ id: string }>();
  const [currentData, setCurrentData] = useState(Object);
  const [loading, setLoading] = useState(false);
  const [checkedCode, setCheckedCode] = useState(true);

  const { data: detailRatePlan, isValidating: loadingDetailRatePlan } = useSWR(
    [selectedHotelId, ratePlanId, 'getDetailRatePlan'],
    rateService.getDetailRatePlan
  );

  useEffect(() => {
    if (detailRatePlan) {
      form.setFieldsValue({
        name: detailRatePlan?.data?.name,
        code: detailRatePlan?.data?.code,
        description: detailRatePlan?.data?.description,
        breakfast: detailRatePlan?.data?.breakfast,
        maxStay: detailRatePlan?.data?.maxStay,
        minStay: detailRatePlan?.data?.minStay,
      });
      setCurrentData(detailRatePlan);
    }
    //eslint-disable-next-line
  }, [detailRatePlan]);

  const handleUpdateRatePlan = async () => {
    setLoading(true);
    form.validateFields().then(async (value) => {
      const body: RatePlanPayloadDto = {
        name: value.name,
        description: value.description,
        code: value.code || detailRatePlan?.data?.code,
        minStay: Number(value.minStay) || null,
        maxStay: Number(value.maxStay) || null,
        breakfast: value.breakfast,
        roomTypes: currentData?.data?.roomTypes,
      };
      try {
        setLoading(false);
        await rateService.updateRatePlan(selectedHotelId, ratePlanId, body);
        addSuccess(`Successfully Update Rate Plan`);
        history.goBack();
      } catch (error) {
        setLoading(false);
      }
    });
  };

  const handleValidateCode = async () => {
    if (form.getFieldValue('code') !== detailRatePlan?.data?.code) {
      setCheckedCode(false);
      setLoading(true);
      const body = {
        code: `${form.getFieldValue('code')}`,
      };
      try {
        const response = await rateService.validateCodeRatePlan(selectedHotelId, body);
        if (response.data.available) {
          addSuccess('Code Available');
          setLoading(false);
          setCheckedCode(true);
        } else {
          addError('Code Not Available');
          setLoading(false);
          setCheckedCode(false);
        }
      } catch (error) {
        setLoading(false);
        setCheckedCode(false);
      }
    }
  };

  return (
    <ContentHelmet title="Profile">
      <ContentLayout
        BreadcrumbItems={[
          { href: '/', title: 'Home' },
          { href: `/hotels/${selectedHotelId}/rooms/rates`, title: 'Rates' },
          { href: `/hotels/${selectedHotelId}/rooms/rates?tab=rate-plans`, title: 'Rate Plans' },
          { href: window.location.pathname, title: 'Edit Rate Plan' },
        ]}
      >
        <Spin spinning={loadingDetailRatePlan || loading}>
          <div className="header-edit-rate-plan">
            <ArrowLeftOutlined
              style={{ cursor: 'pointer', color: '#1890FF', marginRight: 18 }}
              onClick={() => history.goBack()}
            />
            <Typography style={{ fontSize: 16, fontWeight: 600 }}>Edit Rate Plan</Typography>
          </div>
          <Divider style={{ margin: 0 }} />
          <Form layout="vertical" form={form} size="middle" style={{ padding: 24 }} onFinish={handleUpdateRatePlan}>
            <Row gutter={[32, 8]}>
              <Col span={12}>
                <Form.Item
                  label="Plan Name"
                  name="name"
                  rules={[{ required: true, message: 'Please input plan name' }]}
                >
                  <Input placeholder="Input plan name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Rate Description" name="description">
                  <TextArea placeholder="Input rate description" rows={2} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Rate Code" name="code">
                  <Input
                    placeholder="Input rate code"
                    onBlur={handleValidateCode}
                    onChange={(event) => {
                      if (!event.target.value) {
                        setCheckedCode(true);
                      }
                      let pattern = /^$|^[a-zA-Z0-9]+$/;
                      if (pattern.test(event.target.value)) {
                        form.setFieldsValue({
                          code: event.target.value.toUpperCase(),
                        });
                      } else {
                        form.setFieldsValue({
                          code: event.target.value.toUpperCase().slice(0, -1),
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ marginTop: 0 }} />
            <Typography style={{ fontSize: 14, fontWeight: 600, marginBottom: 24 }}>General</Typography>
            <Row gutter={[32, 8]}>
              <Col span={12}>
                <Form.Item label="Minimum Stay" name="minStay">
                  <Input
                    placeholder="Input minimum stay"
                    addonAfter="night"
                    onChange={(event) => {
                      let pattern = /^$|^[0-9]+$/;
                      if (pattern.test(event.target.value)) {
                        form.setFieldsValue({
                          minStay: event.target.value,
                        });
                      } else {
                        form.setFieldsValue({
                          minStay: event.target.value.slice(0, -1),
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Maximum Stay" name="maxStay">
                  <Input
                    placeholder="Input maximum stay"
                    addonAfter="night"
                    onChange={(event) => {
                      let pattern = /^$|^[0-9]+$/;
                      if (pattern.test(event.target.value)) {
                        form.setFieldsValue({
                          maxStay: event.target.value,
                        });
                      } else {
                        form.setFieldsValue({
                          maxStay: event.target.value.slice(0, -1),
                        });
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Breakfast Availability"
                  name="breakfast"
                  rules={[{ required: true, message: 'Please select breakfast availability' }]}
                >
                  <Radio.Group>
                    <Radio value={true}>Breakfast Included</Radio>
                    <Radio value={false}>Breakfast Excluded</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div className="divider-rate-plan">
              <Divider />
            </div>
            <Typography style={{ fontSize: 14, fontWeight: 600, marginBottom: 24 }}>Room Types</Typography>
            <div>
              <div className="header-room-types">
                <Typography style={{ fontSize: 14, fontWeight: 700, width: '50%' }}>Room Name</Typography>
                <Divider type="vertical" style={{ margin: '0px 16px' }} />
                <Typography style={{ fontSize: 14, fontWeight: 700, width: '50%' }}>Room Plan Name</Typography>
              </div>
              <div className="body-room-types">
                <Row gutter={[32, 32]}>
                  {currentData?.data?.roomTypes.map((item, index) => (
                    <>
                      <Col span={12}>
                        <Typography>{item.roomName}</Typography>
                      </Col>
                      <Col span={12}>
                        <Input
                          placeholder="Input room plan name (e.g. Deluxe Twin with Breakfast)"
                          value={item.roomRateName}
                          onChange={(event) => {
                            let newData = currentData?.data;
                            newData.roomTypes[index].roomRateName = event.target.value;
                            setCurrentData({ ...currentData, data: newData });
                          }}
                        />
                      </Col>
                    </>
                  ))}
                </Row>
              </div>
            </div>
            <Divider style={{ marginTop: 8 }} />
            <Form.Item style={{ textAlign: 'end', marginBottom: 0 }}>
              <Button id="button-save" htmlType="button" style={{ marginRight: 16 }} onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button id="button-save" type="primary" htmlType="submit" disabled={!checkedCode}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </ContentLayout>
    </ContentHelmet>
  );
}
