import { axiosInstance } from '@config/axios-instance-v2';
import { BannerAddDto } from '@libs/dto';

const url = `/admin/banners`;
export const bannerService = {
  list({ page = '1', per_page = '10' }: { page: string; per_page: string }) {
    return axiosInstance.get(`${url}`, {
      params: { page, per_page },
    });
  },

  create(data: BannerAddDto) {
    return axiosInstance.post(`${url}`, data);
  },
  update(id: string, data: BannerAddDto) {
    return axiosInstance.put(`${url}/${id}`, data);
  },
  delete(id: string) {
    return axiosInstance.delete(`${url}/${id}`);
  },
};

export type BannerService = typeof bannerService;
