import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined';
import BookOutlined from '@ant-design/icons/BookOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import BankOutlined from '@ant-design/icons/BankOutlined';
import CoffeeOutlined from '@ant-design/icons/CoffeeOutlined';
import SmileOutlined from '@ant-design/icons/SmileOutlined';
import MembershipIcon from '@assets/membership-icon.jsx';
import BalancesIcon from '@assets/balances-icon.jsx';
import GiftOutlined from '@ant-design/icons/GiftOutlined';
import FormOutlined from '@ant-design/icons/FormOutlined';
import { FileSearchOutlined, NotificationOutlined, ShopOutlined } from '@ant-design/icons';
import { ActionsEnum, SubjectsEnum } from '@libs/enums';

const copySouvenirModule = process.env.REACT_APP_COPY_SOUVENIR_MODULE || 'Souvenir';

export interface MenuInterface {
  title: string;
  linkTo?: string;
  key?: string;
  Icon?: React.ElementType;
  permissions?: Array<{ subject: string; action: string }>;
  isParent?: boolean;
  action?: () => void;
  hidden?: boolean;
  subMenu?: Array<{
    title: string;
    linkTo: string;
    subKey?: string;
    Icon?: React.ElementType;
    isParent?: boolean;
    subMenu?: MenuInterface['subMenu'];
    permissions?: Array<{ subject: string; action: string }>;
    action?: () => void;
    hidden?: boolean;
  }>;
}

export interface AppMenu extends Array<MenuInterface> {}

export const superAdminMenus: AppMenu = [
  {
    title: 'Dashboard',
    linkTo: '/dashboard',
    Icon: AppstoreOutlined,
    key: '1',
    permissions: [
      { subject: SubjectsEnum.HOTEL_DASHBOARD, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
  },
  {
    title: 'Hotels',
    linkTo: '/hotels',
    Icon: BankOutlined,
    key: '2',
    permissions: [
      { subject: SubjectsEnum.HOTEL, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
  },
  {
    title: 'Guest',
    linkTo: '/guests',
    Icon: SmileOutlined,
    key: '3',
    permissions: [
      { subject: SubjectsEnum.GUEST, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
  },
  {
    title: 'Membership',
    linkTo: '/membership',
    Icon: MembershipIcon,
    key: '4',
    permissions: [
      { subject: SubjectsEnum.MEMBERSHIP, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
  },
  {
    title: 'Rooms',
    Icon: BookOutlined,
    isParent: true,
    key: '5',
    permissions: [
      { subject: SubjectsEnum.ROOM_DASHBOARD, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ROOM_ORDER, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ROOM, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
    subMenu: [
      {
        title: 'Dashboard',
        linkTo: '/rooms/dashboard',
        subKey: '5.1',
        permissions: [
          { subject: SubjectsEnum.ROOM_DASHBOARD, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Orders',
        linkTo: '/rooms/orders',
        subKey: '5.2',
        permissions: [
          { subject: SubjectsEnum.ROOM_ORDER, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Settings',
        linkTo: '/rooms/settings?tab=beds',
        subKey: '5.3',
        permissions: [
          { subject: SubjectsEnum.ROOM, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
    ],
  },
  {
    title: 'Foods',
    Icon: CoffeeOutlined,
    isParent: true,
    key: '6',
    permissions: [
      { subject: SubjectsEnum.FOOD_DASHBOARD, action: ActionsEnum.READ },
      { subject: SubjectsEnum.FOOD_ORDER, action: ActionsEnum.READ },
      { subject: SubjectsEnum.FOOD, action: ActionsEnum.READ },
      { subject: SubjectsEnum.FOOD_PAYMENT, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
    subMenu: [
      {
        title: 'Dashboard',
        linkTo: '/foods/dashboard',
        subKey: '6.1',
        permissions: [
          { subject: SubjectsEnum.FOOD_DASHBOARD, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Orders',
        linkTo: '/foods/orders',
        subKey: '6.2',
        permissions: [
          { subject: SubjectsEnum.FOOD_ORDER, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Settings',
        linkTo: '/foods/settings?tab=featureAvailability',
        subKey: '6.3',
        permissions: [
          { subject: SubjectsEnum.FOOD, action: ActionsEnum.READ },
          { subject: SubjectsEnum.FOOD_PAYMENT, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
    ],
  },
  {
    title: copySouvenirModule,
    Icon: GiftOutlined,
    isParent: true,
    key: '7',
    permissions: [
      { subject: SubjectsEnum.SOUVENIR_DASHBOARD, action: ActionsEnum.READ },
      { subject: SubjectsEnum.SOUVENIR_ORDER, action: ActionsEnum.READ },
      { subject: SubjectsEnum.SOUVENIR, action: ActionsEnum.READ },
      { subject: SubjectsEnum.SOUVENIR_PAYMENT, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
    subMenu: [
      {
        title: 'Dashboard',
        linkTo: '/souvenirs/dashboard',
        subKey: '7.1',
        permissions: [
          { subject: SubjectsEnum.SOUVENIR_DASHBOARD, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Orders',
        linkTo: '/souvenirs/orders',
        subKey: '7.2',
        permissions: [
          { subject: SubjectsEnum.SOUVENIR_ORDER, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Settings',
        linkTo: '/souvenirs/settings',
        subKey: '7.3',
        permissions: [
          { subject: SubjectsEnum.SOUVENIR, action: ActionsEnum.READ },
          { subject: SubjectsEnum.SOUVENIR_PAYMENT, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
    ],
  },
  {
    title: 'Marketplace',
    Icon: ShopOutlined,
    isParent: true,
    key: '8',
    permissions: [
      { subject: SubjectsEnum.BALANCE, action: ActionsEnum.READ },
      { subject: SubjectsEnum.BALANCE_TOPUP, action: ActionsEnum.READ },
      { subject: SubjectsEnum.BALANCE_WITHDRAW, action: ActionsEnum.READ },
      { subject: SubjectsEnum.BUSINESS_MODEL, action: ActionsEnum.READ },
      { subject: SubjectsEnum.REFERRAL, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
    subMenu: [
      {
        title: 'Hotel Balances',
        linkTo: '/marketplace/hotel-balances',
        subKey: '8.1',
        permissions: [
          { subject: SubjectsEnum.BALANCE, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Top Ups',
        linkTo: '/marketplace/top-ups',
        subKey: '8.2',
        permissions: [
          { subject: SubjectsEnum.BALANCE_TOPUP, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Withdrawals',
        linkTo: '/marketplace/withdrawals',
        subKey: '8.3',
        permissions: [
          { subject: SubjectsEnum.BALANCE_WITHDRAW, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Settings',
        linkTo: '/marketplace/settings',
        subKey: '8.4',
        permissions: [
          { subject: SubjectsEnum.BUSINESS_MODEL, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Referrals',
        linkTo: '/marketplace/referrals',
        subKey: '8.5',
        permissions: [
          { subject: SubjectsEnum.REFERRAL, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
    ],
  },
  {
    title: 'Marketing',
    Icon: NotificationOutlined,
    isParent: true,
    key: '9',
    permissions: [
      { subject: SubjectsEnum.PROMO_CODE, action: ActionsEnum.READ },
      { subject: SubjectsEnum.VOUCHER, action: ActionsEnum.READ },
      { subject: SubjectsEnum.BANNER, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
    subMenu: [
      {
        title: 'Promo Codes',
        linkTo: '/promo-codes',
        subKey: '9.1',
        permissions: [
          { subject: SubjectsEnum.PROMO_CODE, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Vouchers',
        linkTo: '/vouchers',
        subKey: '9.2',
        permissions: [
          { subject: SubjectsEnum.VOUCHER, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Banners',
        linkTo: '/banners',
        subKey: '9.3',
        permissions: [
          { subject: SubjectsEnum.BANNER, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
    ],
  },
  {
    title: 'Activity Log',
    linkTo: '/activity-logs',
    Icon: FileSearchOutlined,
    key: '10',
    permissions: [
      { subject: SubjectsEnum.ACTIVITY_LOG, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
  },
  {
    title: 'CMS',
    linkTo: '/cms?tab=categories',
    Icon: FormOutlined,
    key: '11',
    permissions: [
      { subject: SubjectsEnum.CMS, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
  },
  {
    title: 'Settings',
    linkTo: '/settings',
    Icon: SettingOutlined,
    key: '12',
    permissions: [
      { subject: SubjectsEnum.ROLE, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ADMIN, action: ActionsEnum.READ },
      { subject: SubjectsEnum.CITY, action: ActionsEnum.READ },
      { subject: SubjectsEnum.HOTEL, action: ActionsEnum.READ },
      { subject: SubjectsEnum.GUEST, action: ActionsEnum.READ },
      { subject: SubjectsEnum.POINT, action: ActionsEnum.READ },
      { subject: SubjectsEnum.PAYMENT, action: ActionsEnum.READ },
      { subject: SubjectsEnum.PAYMENT_GATEWAY, action: ActionsEnum.READ },
      { subject: SubjectsEnum.PAYMENT_CHANNEL, action: ActionsEnum.READ },
      { subject: SubjectsEnum.REFERRAL, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
  },
];

export const adminMenus: AppMenu = [
  {
    title: 'Dashboard',
    linkTo: `/hotels/:id/dashboard`,
    Icon: AppstoreOutlined,
    key: '1',
    permissions: [
      { subject: SubjectsEnum.HOTEL_DASHBOARD, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
  },
  {
    title: 'Rooms',
    Icon: BookOutlined,
    isParent: true,
    key: '2',
    permissions: [
      { subject: SubjectsEnum.ROOM_DASHBOARD, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ROOM_ORDER, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ROOM_AVAILABILITY, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ROOM_RATE, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ROOM, action: ActionsEnum.READ },
      { subject: SubjectsEnum.RATE_PLAN, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
    subMenu: [
      {
        title: 'Dashboard',
        linkTo: `/hotels/:id/rooms/dashboard`,
        subKey: '2.1',
        permissions: [
          { subject: SubjectsEnum.ROOM_DASHBOARD, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Orders',
        linkTo: `/hotels/:id/rooms/orders`,
        subKey: '2.2',
        permissions: [
          { subject: SubjectsEnum.ROOM_ORDER, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Availabilities',
        linkTo: `/hotels/:id/rooms/availability`,
        subKey: '2.3',
        permissions: [
          { subject: SubjectsEnum.ROOM_AVAILABILITY, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Rates',
        linkTo: `/hotels/:id/rooms/rates`,
        subKey: '2.4',
        permissions: [
          { subject: SubjectsEnum.ROOM_RATE, action: ActionsEnum.READ },
          { subject: SubjectsEnum.RATE_PLAN, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Settings',
        linkTo: `/hotels/:id/rooms/settings`,
        subKey: '2.5',
        permissions: [
          { subject: SubjectsEnum.ROOM, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
    ],
  },
  {
    title: 'Foods',
    Icon: CoffeeOutlined,
    isParent: true,
    key: '3',
    permissions: [
      { subject: SubjectsEnum.FOOD_DASHBOARD, action: ActionsEnum.READ },
      { subject: SubjectsEnum.FOOD_ORDER, action: ActionsEnum.READ },
      { subject: SubjectsEnum.FOOD, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
    subMenu: [
      {
        title: 'Dashboard',
        linkTo: `/hotels/:id/foods/dashboard`,
        subKey: '3.1',
        permissions: [
          { subject: SubjectsEnum.FOOD_DASHBOARD, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Orders',
        linkTo: `/hotels/:id/foods/orders`,
        subKey: '3.2',
        permissions: [
          { subject: SubjectsEnum.FOOD_ORDER, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Settings',
        linkTo: `/hotels/:id/foods/settings?tab=foods`,
        subKey: '3.3',
        permissions: [
          { subject: SubjectsEnum.FOOD, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
    ],
  },
  {
    title: copySouvenirModule,
    Icon: GiftOutlined,
    isParent: true,
    key: '4',
    permissions: [
      { subject: SubjectsEnum.SOUVENIR_DASHBOARD, action: ActionsEnum.READ },
      { subject: SubjectsEnum.SOUVENIR_ORDER, action: ActionsEnum.READ },
      { subject: SubjectsEnum.SOUVENIR, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
    subMenu: [
      {
        title: 'Dashboard',
        linkTo: `/hotels/:id/souvenirs/dashboard`,
        subKey: '4.1',
        permissions: [
          { subject: SubjectsEnum.SOUVENIR_DASHBOARD, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Orders',
        linkTo: `/hotels/:id/souvenirs/orders`,
        subKey: '4.2',
        permissions: [
          { subject: SubjectsEnum.SOUVENIR_ORDER, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
      {
        title: 'Settings',
        linkTo: `/hotels/:id/souvenirs/settings?tabs=souvenirs`,
        subKey: '4.3',
        permissions: [
          { subject: SubjectsEnum.SOUVENIR, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
    ],
  },
  {
    title: 'Balances',
    linkTo: `/hotels/:id/balances`,
    Icon: BalancesIcon,
    key: '5',
    permissions: [
      { subject: SubjectsEnum.BALANCE, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
  },
  {
    title: 'Marketing',
    Icon: NotificationOutlined,
    isParent: true,
    key: '6',
    permissions: [
      { subject: SubjectsEnum.PROMO_CODE, action: ActionsEnum.READ },
      { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
    ],
    subMenu: [
      {
        title: 'Promo Codes',
        linkTo: '/hotels/:id/promo-codes',
        subKey: '6.1',
        permissions: [
          { subject: SubjectsEnum.PROMO_CODE, action: ActionsEnum.READ },
          { subject: SubjectsEnum.ALL, action: ActionsEnum.MANAGE },
        ],
      },
    ],
  },
  {
    title: 'Settings',
    linkTo: `/hotels/:id/settings`,
    Icon: SettingOutlined,
    key: '7',
  },
];
