import Axios, { AxiosRequestConfig } from 'axios';
import { useNotif } from '@hooks/use-notif';

export const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const { addError } = useNotif();
axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const getToken = localStorage.getItem('token');

  return {
    ...config,
    headers: {
      Authorization: `Bearer ${getToken}`,
      ...config.headers,
    },
  };
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // eslint-disable-next-line no-console
    addError(error);
    // eslint-disable-next-line no-alert
    return Promise.reject(error);
  }
);
