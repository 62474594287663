import React from 'react';

export default function BalancesIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: '10px' }}
    >
      <mask id="path-1-inside-1_4859_65348" fill="white">
        <rect x="1" y="2" width="14" height="11" rx="0.5" />
      </mask>
      <rect
        x="1"
        y="2"
        width="14"
        height="11"
        rx="0.5"
        stroke="white"
        strokeWidth="2"
        mask="url(#path-1-inside-1_4859_65348)"
      />
      <path
        d="M7.69105 11.8182V3.63636H8.2152V11.8182H7.69105ZM9.20277 6.25391C9.17294 5.97479 9.04723 5.75746 8.82564 5.60192C8.60618 5.44638 8.32067 5.36861 7.96911 5.36861C7.72195 5.36861 7.50994 5.40589 7.3331 5.48047C7.15625 5.55504 7.02095 5.65625 6.9272 5.78409C6.83345 5.91193 6.78551 6.05788 6.78338 6.22195C6.78338 6.35831 6.81428 6.47656 6.87607 6.5767C6.93999 6.67685 7.02628 6.76207 7.13494 6.83239C7.24361 6.90057 7.36399 6.9581 7.49609 7.00497C7.6282 7.05185 7.76136 7.09126 7.8956 7.12322L8.50923 7.27663C8.75639 7.33416 8.99396 7.41193 9.22195 7.50994C9.45206 7.60795 9.65767 7.73153 9.83878 7.88068C10.022 8.02983 10.1669 8.20987 10.2734 8.42081C10.38 8.63175 10.4332 8.87891 10.4332 9.16229C10.4332 9.54581 10.3352 9.88352 10.1392 10.1754C9.94318 10.4652 9.6598 10.6921 9.28906 10.8562C8.92045 11.0181 8.47408 11.0991 7.94993 11.0991C7.4407 11.0991 6.99858 11.0202 6.62358 10.8626C6.25071 10.7049 5.95881 10.4748 5.74787 10.1722C5.53906 9.86967 5.42614 9.50107 5.40909 9.06641H6.57564C6.59268 9.29439 6.663 9.48402 6.78658 9.6353C6.91016 9.78658 7.07102 9.8995 7.26918 9.97408C7.46946 10.0487 7.69318 10.0859 7.94034 10.0859C8.19815 10.0859 8.42401 10.0476 8.6179 9.97088C8.81392 9.89205 8.96733 9.78338 9.07812 9.64489C9.18892 9.50426 9.24538 9.3402 9.24751 9.1527C9.24538 8.98224 9.19531 8.84162 9.0973 8.73082C8.99929 8.6179 8.86186 8.52415 8.68501 8.44957C8.5103 8.37287 8.30575 8.30469 8.07138 8.24503L7.3267 8.05327C6.78764 7.91477 6.36151 7.7049 6.0483 7.42365C5.73722 7.14027 5.58168 6.7642 5.58168 6.29545C5.58168 5.9098 5.68608 5.57209 5.89489 5.28232C6.10582 4.99254 6.3924 4.76776 6.75462 4.60795C7.11683 4.44602 7.52699 4.36506 7.98509 4.36506C8.44957 4.36506 8.85653 4.44602 9.20597 4.60795C9.55753 4.76776 9.83345 4.99041 10.0337 5.27592C10.234 5.5593 10.3374 5.8853 10.3438 6.25391H9.20277Z"
        fill="white"
      />
    </svg>
  );
}
