import { axiosInstance } from '@config/axios-instance-v2';
import { BedAddDto, BedUpdateDto } from '@libs/dto/bed';

const url = 'beds';

export const bedService = {
  list({ page = '1', per_page = '10' }: { page: string; per_page: string }) {
    return axiosInstance.get(`/admin/rooms/settings/${url}?page=${page}&per_page=${per_page}`);
  },
  get(id: string) {
    return axiosInstance.get(`/admin/rooms/settings/${url}/${id}`);
  },
  create(data: BedAddDto) {
    return axiosInstance.post(`/admin/rooms/settings/${url}`, data);
  },
  update(id: string, data: BedUpdateDto) {
    return axiosInstance.put(`/admin/rooms/settings/${url}/${id}`, data);
  },
  delete(id: string) {
    return axiosInstance.delete(`/admin/rooms/settings/${url}/${id}`);
  },
};
export type BedService = typeof bedService;
