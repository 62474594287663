/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { notification } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

export const useNotif = () => {
  function addError(error: any) {
    if (process.env.NODE_ENV === 'development') {
      // tslint:disable-next-line: no-console
      // eslint-disable-next-line no-console
    }

    let msg: string;
    if (error && typeof error === 'object' && error?.message) {
      msg = error?.response?.data?.meta?.message || 'Something Went Wrong';
    } else if (error && typeof error === 'string') {
      msg = error;
    } else {
      msg = `${error?.meta?.message}`;
    }

    return notification.error({
      message: msg,
    });
  }

  function addSuccess(success: any) {
    let msg: string;

    if (success && success.message) {
      msg = success.message;
    } else if (success && typeof success === 'string') {
      msg = success;
    } else {
      msg = 'Berhasil';
    }

    return notification.success({
      message: msg,
    });
  }

  function addInfo(success: any) {
    let msg: string;

    if (success && success.message) {
      msg = success.message;
    } else if (success && typeof success === 'string') {
      msg = success;
    } else {
      msg = 'Berhasil';
    }

    return notification.info({
      message: <p style={{ color: 'white' }}>{msg}</p>,
      icon: <InfoCircleFilled style={{ color: 'white' }} />,
      style: {
        marginTop: 50,
        marginBottom: -40,
        backgroundColor: '#2196f3',
        color: 'white !important',
        paddingTop: 10,
        paddingBottom: 0,
      },
      closeIcon: <InfoCircleFilled style={{ color: 'white', display: 'none' }} />,
    });
  }

  return { addError, addSuccess, addInfo };
};
