import { axiosInstance } from '@config/axios-instance-v2';
import {
  SouvenirAddDto,
  BulkUpdateStatusSouvenir,
  PaymentConfigureSouvenirPayloadDto,
  PaymentConfigureSouvenirDto,
  SouvenirDetailDto,
} from '@libs/dto/souvenir';

let url = `/admin/hotels`;
let urlSuper = '/admin';
let urlSouvenir = '/admin/souvenirs';

export const souvenirService = {
  list(id: string, { page = '1', per_page = '10', status = '' }: { page: string; per_page: string; status?: string }) {
    return axiosInstance.get(`${url}/${id}/souvenirs/settings/souvenirs`, {
      params: {
        page,
        per_page,
        status,
      },
    });
  },
  async create(hotel_id: string, data: SouvenirAddDto) {
    const response = await axiosInstance.post(`/admin/hotels/${hotel_id}/souvenirs/settings/souvenirs`, data);
    return response;
  },
  async get(hotel_id: string, id: string) {
    const response: SouvenirDetailDto = await axiosInstance.get(
      `${url}/${hotel_id}/souvenirs/settings/souvenirs/${id}`
    );
    return response;
  },
  update(hotel_id: string, id: string, data: SouvenirAddDto) {
    return axiosInstance.put(`${url}/${hotel_id}/souvenirs/settings/souvenirs/${id}`, data);
  },
  delete(hotel_id: string, id: string) {
    return axiosInstance.delete(`${url}/${hotel_id}/souvenirs/settings/souvenirs/${id}`);
  },
  listSouvenirUnits(page: number, per_page: number) {
    return axiosInstance.get(`${urlSuper}/souvenirs/settings/units`, { params: { page, per_page } });
  },
  createSouvenirUnits(data: SouvenirAddDto) {
    return axiosInstance.post(`${urlSuper}/souvenirs/settings/units`, data);
  },
  updateSouvenirUnits(id: string, data: SouvenirAddDto) {
    return axiosInstance.put(`${urlSuper}/souvenirs/settings/units/${id}`, data);
  },
  deleteSouvenirUnits(id: string) {
    return axiosInstance.delete(`${urlSuper}/souvenirs/settings/units/${id}`);
  },
  getListEnable(page: number, per_page: number) {
    return axiosInstance.get(`/admin/souvenirs/settings/hotels`, { params: { page, per_page } });
  },
  updateEnable(data: BulkUpdateStatusSouvenir) {
    return axiosInstance.put(`/admin/souvenirs/settings/hotels/bulk-modify-feature-status`, data);
  },
  async getPaymentConfigureSouvenir() {
    const response: PaymentConfigureSouvenirDto = await axiosInstance.get(
      `${urlSouvenir}/settings/payment-configuration`
    );
    return response;
  },
  async updatePaymentConfigureSouvenir(data: PaymentConfigureSouvenirPayloadDto) {
    const response = await axiosInstance.put(`${urlSouvenir}/settings/payment-configuration`, data, {});
    return response;
  },
};

export type SouvenirService = typeof souvenirService;
