import { axiosInstance } from '@config/axios-instance-v2';
import { AddHotelFacilityDto } from '@libs/dto';

const url = '/admin/settings/hotel-property-facilities';

export const hotelFacilityService = {
  list({ page, per_page }: { page: string; per_page: string }) {
    return axiosInstance.get(`${url}?page=${page || '1'}&per_page=${per_page || '10'}`);
  },
  create(data: AddHotelFacilityDto) {
    return axiosInstance.post(`${url}`, data);
  },
  update(id: string, data: AddHotelFacilityDto) {
    return axiosInstance.put(`${url}/${id}`, data);
  },
  delete(id: string) {
    return axiosInstance.delete(`${url}/${id}`);
  },
};

export type HotelFacilityService = typeof hotelFacilityService;
