import { axiosInstance } from '@config/axios-instance-v2';
import {
  ReportFoodRevenueDto,
  ReportHotelTopTransactionDto,
  ReportRoomRevenueDto,
  ReportSouvenirRevenueDto,
} from '@libs/dto';

const url = '/admin/hotels';

export const dashboardAdminService = {
  async getRoomRevenue(selectedHotelId, { startDate, endDate }: { startDate: string; endDate: string }) {
    const response: ReportRoomRevenueDto = await axiosInstance.get(
      `${url}/${selectedHotelId}/rooms/reports/total-revenue`,
      {
        params: {
          startDate,
          endDate,
        },
      }
    );
    return response;
  },
  async getFoodRevenue(selectedHotelId, { startDate, endDate }: { startDate: string; endDate: string }) {
    const response: ReportFoodRevenueDto = await axiosInstance.get(
      `${url}/${selectedHotelId}/foods/reports/total-revenue`,
      {
        params: {
          startDate,
          endDate,
        },
      }
    );
    return response;
  },
  async getSouvenirRevenue(selectedHotelId, { startDate, endDate }: { startDate: string; endDate: string }) {
    const response: ReportSouvenirRevenueDto = await axiosInstance.get(
      `${url}/${selectedHotelId}/souvenirs/reports/total-revenue`,
      {
        params: {
          startDate,
          endDate,
        },
      }
    );
    return response;
  },
  async getTopTransaction(
    selectedHotelId,
    {
      startDate,
      endDate,
      sortBy = 'totalPrice',
      orderBy = 'desc',
    }: { startDate: string; endDate: string; sortBy: string; orderBy: string }
  ) {
    const response: ReportHotelTopTransactionDto = await axiosInstance.get(
      `${url}/${selectedHotelId}/reports/top-transaction`,
      {
        params: {
          startDate,
          endDate,
          sortBy,
          orderBy,
        },
      }
    );
    return response;
  },
  async getTopRoomTransaction(
    selectedHotelId,
    {
      startDate,
      endDate,
      sortBy = 'totalPrice',
      orderBy = 'desc',
    }: { startDate: string; endDate: string; sortBy: string; orderBy: string }
  ) {
    const response: ReportHotelTopTransactionDto = await axiosInstance.get(
      `${url}/${selectedHotelId}/rooms/reports/top-transaction`,
      {
        params: {
          startDate,
          endDate,
          sortBy,
          orderBy,
        },
      }
    );
    return response;
  },
  async getTopFoodTransaction(
    selectedHotelId,
    {
      startDate,
      endDate,
      sortBy = 'totalPrice',
      orderBy = 'desc',
    }: { startDate: string; endDate: string; sortBy: string; orderBy: string }
  ) {
    const response: ReportHotelTopTransactionDto = await axiosInstance.get(
      `${url}/${selectedHotelId}/foods/reports/top-transaction`,
      {
        params: {
          startDate,
          endDate,
          sortBy,
          orderBy,
        },
      }
    );
    return response;
  },
  async getTopSouvenirTransaction(
    selectedHotelId,
    {
      startDate,
      endDate,
      sortBy = 'totalPrice',
      orderBy = 'desc',
    }: { startDate: string; endDate: string; sortBy: string; orderBy: string }
  ) {
    const response: ReportHotelTopTransactionDto = await axiosInstance.get(
      `${url}/${selectedHotelId}/souvenirs/reports/top-transaction`,
      {
        params: {
          startDate,
          endDate,
          sortBy,
          orderBy,
        },
      }
    );
    return response;
  },
};

export type DashboardAdminService = typeof dashboardAdminService;
