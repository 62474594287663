import { ActionsEnum, SubjectsEnum } from '@libs/enums';
import ability from '../ability';

const identity = JSON.parse(localStorage.getItem('uat'));
// const layout = localStorage.getItem('layout');
const selectedHotelId = localStorage.getItem('selectedHotelId');
const selectedHotel = identity?.hotels.find((hotel) => hotel?.id === selectedHotelId);
const findAllPermission = identity?.permissions?.find((item) => item.subject === 'all' && item.action === 'manage');

export const permissionActionSuperadmin = (action: string, subject: string) => {
  const findPermission = identity?.permissions?.find((item) => item.subject === subject && item.action === action);
  if (findAllPermission) {
    return true;
  }
  if (!findPermission) {
    return false;
  }
  return ability.can(action, subject);
};

export const permissionActionHotelAdmin = (action: string, subject: string, hotel?: any) => {
  let findPermission;
  if (hotel) {
    findPermission = hotel?.permissions?.find((item) => item.subject === subject && item.action === action);
  } else {
    findPermission = selectedHotel?.permissions?.find((item) => item.subject === subject && item.action === action);
  }
  if (findAllPermission) {
    return true;
  }
  if (!findPermission) {
    return false;
  }
  return ability.can(action, subject);
};

export const permissions = (action: string, subject: string) => {
  const hotelId = localStorage.getItem('selectedHotelId');

  if (!hotelId) {
    return permissionActionSuperadmin(action, subject);
  }
  if (hotelId) {
    return permissionActionHotelAdmin(action, subject);
  }
};

export const redirectSuperAdmin = () => {
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.HOTEL_DASHBOARD)) {
    window.location.href = '/dashboard';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.HOTEL)) {
    window.location.href = '/hotels';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.GUEST)) {
    window.location.href = '/guests';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.MEMBERSHIP)) {
    window.location.href = '/membership';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.ROOM_DASHBOARD)) {
    window.location.href = '/rooms/dashboard';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.ROOM_ORDER)) {
    window.location.href = '/rooms/orders';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.ROOM)) {
    window.location.href = '/rooms/settings?tab=beds';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.FOOD_DASHBOARD)) {
    window.location.href = '/foods/dashboard';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.FOOD_ORDER)) {
    window.location.href = '/foods/orders';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.FOOD)) {
    window.location.href = '/foods/settings?tab=featureAvailability';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.SOUVENIR_DASHBOARD)) {
    window.location.href = '/souvenirs/dashboard';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.SOUVENIR_ORDER)) {
    window.location.href = '/souvenirs/orders';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.SOUVENIR)) {
    window.location.href = '/souvenirs/settings';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.BALANCE)) {
    window.location.href = '/marketplace/hotel-balances';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.BALANCE_TOPUP)) {
    window.location.href = '/marketplace/top-ups';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.BALANCE_WITHDRAW)) {
    window.location.href = '/marketplace/withdrawals';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.BUSINESS_MODEL)) {
    window.location.href = '/marketplace/settings';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.REFERRAL)) {
    window.location.href = '/marketplace/referrals';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.PROMO_CODE)) {
    window.location.href = '/promo-codes';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.VOUCHER)) {
    window.location.href = '/vouchers';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.BANNER)) {
    window.location.href = '/banners';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.ACTIVITY_LOG)) {
    window.location.href = '/activity-logs';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.CMS)) {
    window.location.href = '/cms?tab=categories';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.ROLE)) {
    window.location.href = '/settings?tab=roles-and-permissions';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.ADMIN)) {
    window.location.href = '/settings?tab=admins';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.CITY)) {
    window.location.href = '/settings?tab=cities';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.POINT)) {
    window.location.href = '/settings?tab=points';
    return;
  }
  if (permissionActionSuperadmin(ActionsEnum.READ, SubjectsEnum.PAYMENT)) {
    window.location.href = '/settings?tab=payment-gateways';
    return;
  } else {
    window.location.href = '/dashboard';
  }
};

export const redirectHotelAdmin = (hotel) => {
  if (permissionActionHotelAdmin(ActionsEnum.READ, SubjectsEnum.HOTEL_DASHBOARD, hotel)) {
    window.location.href = `/hotels/${hotel.id}/dashboard`;
    return;
  }
  if (permissionActionHotelAdmin(ActionsEnum.READ, SubjectsEnum.ROOM_DASHBOARD, hotel)) {
    window.location.href = `/hotels/${hotel.id}/rooms/dashboard`;
    return;
  }
  if (permissionActionHotelAdmin(ActionsEnum.READ, SubjectsEnum.ROOM_ORDER, hotel)) {
    window.location.href = `/hotels/${hotel.id}/rooms/orders`;
    return;
  }
  if (permissionActionHotelAdmin(ActionsEnum.READ, SubjectsEnum.ROOM_AVAILABILITY, hotel)) {
    window.location.href = `/hotels/${hotel.id}/rooms/availability`;
    return;
  }
  if (permissionActionHotelAdmin(ActionsEnum.READ, SubjectsEnum.ROOM_RATE, hotel)) {
    window.location.href = `/hotels/${hotel.id}/rooms/rate`;
    return;
  }
  if (permissionActionHotelAdmin(ActionsEnum.READ, SubjectsEnum.ROOM, hotel)) {
    window.location.href = `/hotels/${hotel.id}/rooms/settings`;
    return;
  }
  if (permissionActionHotelAdmin(ActionsEnum.READ, SubjectsEnum.FOOD_DASHBOARD, hotel)) {
    window.location.href = `/hotels/${hotel.id}/foods/dashboard`;
    return;
  }
  if (permissionActionHotelAdmin(ActionsEnum.READ, SubjectsEnum.FOOD_ORDER, hotel)) {
    window.location.href = `/hotels/${hotel.id}/foods/orders`;
    return;
  }
  if (permissionActionHotelAdmin(ActionsEnum.READ, SubjectsEnum.FOOD, hotel)) {
    window.location.href = `/hotels/${hotel.id}/foods/settings?tab=foods`;
    return;
  }
  if (permissionActionHotelAdmin(ActionsEnum.READ, SubjectsEnum.SOUVENIR_DASHBOARD, hotel)) {
    window.location.href = `/hotels/${hotel.id}/souvenirs/dashboard`;
    return;
  }
  if (permissionActionHotelAdmin(ActionsEnum.READ, SubjectsEnum.SOUVENIR_ORDER, hotel)) {
    window.location.href = `/hotels/${hotel.id}/souvenirs/orders`;
    return;
  }
  if (permissionActionHotelAdmin(ActionsEnum.READ, SubjectsEnum.SOUVENIR, hotel)) {
    window.location.href = `/hotels/${hotel.id}/souvenirs/settings?tab=souvenirs`;
    return;
  }
  if (permissionActionHotelAdmin(ActionsEnum.READ, SubjectsEnum.BALANCE, hotel)) {
    window.location.href = `/hotels/${hotel.id}/balances`;
    return;
  }
  if (permissionActionHotelAdmin(ActionsEnum.READ, SubjectsEnum.PROMO_CODE, hotel)) {
    window.location.href = `/hotels/${hotel.id}/promo-codes`;
    return;
  } else {
    window.location.href = `/hotels/${hotel.id}/settings?tab=qrcode`;
  }
};
