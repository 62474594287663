import { axiosInstance } from '@config/axios-instance-v2';
import { AssignRoomDto, BookingListDto, RoomOrderDetailDto } from '@libs/dto';

const url = '/admin';
const urlAssign = '/admin/hotels';

export const roomOrderService = {
  async list(
    selectedHotelId,
    {
      page = 1,
      per_page = 10,
      start_date = '',
      end_date = '',
      filter_by = '',
      status = '',
      hotelName = '',
      orderBy = '',
      orderId = '',
      guestName = '',
      guestEmail = '',
      guestProfileName = '',
      guestProfileEmail = '',
      invoiceId = '',
      promoCode = '',
    }
  ) {
    if (selectedHotelId) {
      const response: BookingListDto = await axiosInstance.get(`${urlAssign}/${selectedHotelId}/rooms/orders`, {
        params: {
          page,
          per_page,
          filter_by,
          start_date,
          end_date,
          status,
          orderBy,
          orderId,
          guestName,
          guestEmail,
          guestProfileName,
          guestProfileEmail,
          invoiceId,
          promoCode,
        },
      });
      return response;
    } else {
      const response: BookingListDto = await axiosInstance.get(`${url}/rooms/orders`, {
        params: {
          page,
          per_page,
          filter_by,
          start_date,
          end_date,
          status,
          hotelName,
          orderBy,
          orderId,
          guestName,
          guestEmail,
          guestProfileName,
          guestProfileEmail,
          invoiceId,
          promoCode,
        },
      });
      return response;
    }
  },
  async get(id: string, selectedHotelId: string) {
    if (selectedHotelId) {
      const response: RoomOrderDetailDto = await axiosInstance.get(
        `${urlAssign}/${selectedHotelId}/rooms/orders/${id}`
      );
      return response;
    } else {
      const response: RoomOrderDetailDto = await axiosInstance.get(`${url}/rooms/orders/${id}/detail`);
      return response;
    }
  },
  async cancelRoomOrder(id: string, selectedHotelId: string) {
    if (selectedHotelId) {
      const response = await axiosInstance.post(`${urlAssign}/${selectedHotelId}/rooms/orders/${id}/status-cancel`);
      return response;
    } else {
      const response = await axiosInstance.post(`${url}/rooms/orders/${id}/status-cancel`);
      return response;
    }
  },
  async checkoutRoomOrder(id: string, selectedHotelId: string) {
    if (selectedHotelId) {
      const response = await axiosInstance.post(`${urlAssign}/${selectedHotelId}/rooms/orders/${id}/status-checkout`);
      return response;
    } else {
      const response = await axiosInstance.post(`${url}/rooms/orders/${id}/status-checkout`);
      return response;
    }
  },
  async readyRoomOrder(id: string, selectedHotelId: string) {
    if (selectedHotelId) {
      const response = await axiosInstance.post(`${urlAssign}/${selectedHotelId}/rooms/orders/${id}/status-ready`);
      return response;
    } else {
      const response = await axiosInstance.post(`${url}/rooms/orders/${id}/status-ready`);
      return response;
    }
  },
  async checkinRoomOrder(id: string, selectedHotelId: string) {
    if (selectedHotelId) {
      const response = await axiosInstance.post(`${urlAssign}/${selectedHotelId}/rooms/orders/${id}/status-checkin`);
      return response;
    } else {
      const response = await axiosInstance.post(`${url}/rooms/orders/${id}/status-checkin`);
      return response;
    }
  },
  async confirmRoomOrder(id: string, selectedHotelId: string) {
    if (selectedHotelId) {
      const response = await axiosInstance.post(`${urlAssign}/${selectedHotelId}/rooms/orders/${id}/status-confirm`);
      return response;
    } else {
      const response = await axiosInstance.post(`${url}/rooms/orders/${id}/status-confirm`);
      return response;
    }
  },
  async noShowRoomOrder(id: string, selectedHotelId: string) {
    if (selectedHotelId) {
      const response = await axiosInstance.post(`${urlAssign}/${selectedHotelId}/rooms/orders/${id}/status-noshow`);
      return response;
    } else {
      const response = await axiosInstance.post(`${url}/rooms/orders/${id}/status-noshow`);
      return response;
    }
  },
  async expiredRoomOrder(id: string, selectedHotelId: string) {
    if (selectedHotelId) {
      const response = await axiosInstance.post(`${urlAssign}/${selectedHotelId}/rooms/orders/${id}/status-expired`);
      return response;
    } else {
      const response = await axiosInstance.post(`${url}/rooms/orders/${id}/status-expired`);
      return response;
    }
  },
  async waitingRoomOrder(id: string, selectedHotelId: string) {
    if (selectedHotelId) {
      const response = await axiosInstance.post(`${urlAssign}/${selectedHotelId}/rooms/orders/${id}/status-waiting`);
      return response;
    } else {
      const response = await axiosInstance.post(`${url}/rooms/orders/${id}/status-waiting`);
      return response;
    }
  },

  async assignRoomNumber(data: AssignRoomDto, selectedHotelId: string, id: string) {
    if (selectedHotelId) {
      const response = await axiosInstance.post(`${urlAssign}/${selectedHotelId}/rooms/orders/${id}/assign-room`, data);
      return response;
    } else {
      const response = await axiosInstance.post(`${url}/rooms/orders/${id}/assign-room`, data);
      return response;
    }
  },
};

export type BookingService = typeof roomOrderService;
