import { axiosInstance } from '@config/axios-instance-v2';
import { ListHotelTopupsDto } from '@libs/dto';

let url = `/admin/tenant/marketplace/topups`;
let urlAdmin = `/admin`;

export const marketplaceTopupsService = {
  async getListTopups({ page = 1, per_page = 10 }: { page: number; per_page: number }) {
    const response = await axiosInstance.get(`${url}`, {
      params: { page, per_page },
    });
    return response;
  },
  getTopUpById(id?: string) {
    return axiosInstance.get(`${url}/${id}`);
  },
  cancelTopUpById(id?: string) {
    return axiosInstance.put(`${url}/${id}/cancel`, { note: '' });
  },
  async getListHotelTopups(
    selectedHotelId,
    {
      startDate = '',
      endDate = '',
      page = 1,
      per_page = 10,
      id = '',
    }: { startDate: string; endDate: string; page: number; per_page: 10; id: string }
  ) {
    const response: ListHotelTopupsDto = await axiosInstance.get(
      `${urlAdmin}/hotels/${selectedHotelId}/marketplace/topups`,
      {
        params: {
          startDate,
          endDate,
          page,
          per_page,
          id,
        },
      }
    );
    return response;
  },

  getHotelTopUpByIdAdminHotel(selectedHotelId: string, id: string) {
    if (selectedHotelId) {
      return axiosInstance.get(`${urlAdmin}/hotels/${selectedHotelId}/marketplace/topups/${id}`);
    }
  },

  calculateTopUpModal(selectedHotelId: string, data) {
    if (selectedHotelId) {
      return axiosInstance.post(`${urlAdmin}/hotels/${selectedHotelId}/marketplace/topups/calculate`, data);
    }
  },

  postCreateTopUp(selectedHotelId: string, data) {
    if (selectedHotelId) {
      return axiosInstance.post(`${urlAdmin}/hotels/${selectedHotelId}/marketplace/topups`, data);
    }
  },
};

export type MarketplaceTopupsService = typeof marketplaceTopupsService;
