import { axiosInstance } from '@config/axios-instance';

const url = '/report-hotel';

export const reportFoodService = {
  getPerformance() {
    return new Promise((resolve) => {
      resolve({
        data: [
          {
            _id: {
              year: 1,
              month: 1,
              day: 1,
            },
            revenuePerDay: 1,
            order: 1,
          },
        ],
      });
    });
  },
  getCheckinToday(id: string) {
    return axiosInstance.get(`${url}/${id}/checkin-today`);
  },
  getOrderToday(id: string) {
    return axiosInstance.get(`${url}/${id}/booking-today`);
  },
  getTotalRevenue(id: string, period: string) {
    return axiosInstance.get(`${url}/${id}/total-revenue?period=${period.toUpperCase()}`);
  },
  getGuestTransaction(id: string, startDate: string, endDate: string) {
    return axiosInstance.get(`${url}/${id}/guest-transaction?startDate=${startDate}&endDate=${endDate}`);
  },
  getGlobalPerformance() {
    return new Promise((resolve) => {
      resolve({
        data: [
          {
            _id: {
              year: 1,
              month: 1,
              day: 1,
            },
            revenuePerDay: 1,
            order: 1,
          },
        ],
      });
    });
  },
  getGlobalCheckinToday() {
    return axiosInstance.get(`${url}/checkin-today/global`);
  },
  getGlobalOrderToday() {
    return axiosInstance.get(`${url}/booking-today/global`);
  },
  getGlobalTotalRevenue(period: string) {
    return axiosInstance.get(`${url}/total-revenue/global?period=${period.toUpperCase()}`);
  },
  getGlobalGuestTransaction(startDate: string, endDate: string) {
    return axiosInstance.get(`${url}/guest-transaction/global?startDate=${startDate}&endDate=${endDate}`);
  },
};

export type ReportFoodService = typeof reportFoodService;
