import { axiosInstance } from '@config/axios-instance-v2';
import { AddHotelFacilityCategoryDto, UpdateHotelFacilityCategoryDto } from '@libs/dto';

const url = '/admin/settings/hotel-facility-categories';

export const hotelFacilityCategoryService = {
  list(page = 1, per_page = 10) {
    return axiosInstance.get(`${url}`, {
      params: {
        page: page || 1,
        per_page: per_page || 10,
      },
    });
  },
  get(id: string) {
    return axiosInstance.get(`${url}/${id}`);
  },
  create(data: AddHotelFacilityCategoryDto) {
    return axiosInstance.post(`${url}`, data);
  },
  update(id: string, data: UpdateHotelFacilityCategoryDto) {
    return axiosInstance.put(`${url}/${id}`, data);
  },
  delete(id: string) {
    return axiosInstance.delete(`${url}/${id}`);
  },
};

export type HotelFacilityCategoryService = typeof hotelFacilityCategoryService;
