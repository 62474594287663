export enum BookingOrderStatusEnum {
  EXPIRED = 'EXPIRED',
  NOSHOW = 'NOSHOW',
  WAITING = 'WAITING',
  READY = 'READY',
  CONFIRMED = 'CONFIRMED',
  CHECKOUT = 'CHECKOUT',
  CANCEL = 'CANCELED',
  CHECKIN = 'CHECKIN',
  RESCHEDULED = 'RESCHEDULED',
  PAID = 'PAID',
  DEPOSIT_PAID = 'DEPOSIT_PAID',
}

export enum WeekendMarkerEnum {
  sunday = 'Sunday',
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday',
}
