import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useNotif } from '@hooks/use-notif';
import { useService } from '@hooks/use-service';
import { ImageService } from '@services/image';
import { WEB_SERVICES } from '@services/index';
import { Upload } from 'antd';
import React from 'react';

const UploadPhoto = ({
  image,
  setImage,
  setForm,
  information = [],
  alert,
}: {
  image: { url: string; id: string };
  setImage: React.Dispatch<
    React.SetStateAction<{
      id: string;
      url: string;
    }>
  >;
  alert?: boolean;
  information?: string[];
  setForm?: any;
}) => {
  const imageService = useService<ImageService>(WEB_SERVICES.Image);
  const { addError } = useNotif();
  const [loading, setLoading] = React.useState(false);

  const handleUpload = async ({ file }) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('image', file);
      const { data = { id: '', url: '' } } = await imageService.query('upload', [formData]);
      setImage({
        id: data.id,
        url: data.url,
      });
      setForm !== null && setForm(data.id);
    } catch (error) {
      addError(error);
    } finally {
      setLoading(false);
    }
  };

  const UploadButton = () => (
    <div>
      {loading ? <LoadingOutlined /> : <UploadOutlined style={{ color: '#BFBFBF' }} />}
      <div style={{ marginTop: 8, color: '#BFBFBF' }}>Upload</div>
    </div>
  );
  const props = {
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
  };
  return (
    <div>
      <Upload
        {...props}
        listType="picture-card"
        className="photo-uploader"
        showUploadList={false}
        customRequest={handleUpload}
        accept="image/*"
      >
        {!loading && image.url !== '' ? (
          <img src={image.url} alt="foto" style={{ width: '100%', maxHeight: 102, maxWidth: 102 }} />
        ) : (
          <UploadButton />
        )}
      </Upload>
      {alert && <p style={{ color: 'red', marginTop: 10 }}>*Maximum image size is 1 MB</p>}

      {information.map((item, index) => (
        <p style={{ color: 'red', marginTop: 10 }} key={index}>
          {item}
        </p>
      ))}
    </div>
  );
};

export default UploadPhoto;
