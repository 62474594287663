import { axiosInstance } from '@config/axios-instance-v2';
import { ListHotelWithdrawalsDto } from '@libs/dto';

let url = `/admin/tenant/marketplace/withdrawals`;
let urlAdmin = `/admin`;

export const marketplaceWithdrawalService = {
  getListWithdrawal({ page, per_page }: { page: string; per_page: string }) {
    return axiosInstance.get(`${url}`, {
      params: { page, per_page },
    });
  },
  getWithdrawalById(id?: string) {
    return axiosInstance.get(`${url}/${id}`);
  },
  approvedWithdrawalById(id?: string) {
    return axiosInstance.put(`${url}/${id}/approve`, { note: '' });
  },
  cancelWithdrawalById(id?: string) {
    return axiosInstance.put(`${url}/${id}/cancel`, { note: '' });
  },
  rejectWithdrawalById(
    id: string,
    data: {
      note: string;
    }
  ) {
    return axiosInstance.put(`${url}/${id}/reject`, data);
  },
  settleWithdrawalById(id: string, note: string, imageId: string) {
    return axiosInstance.put(`${url}/${id}/settle`, { note, imageId });
  },

  async getListWithdrawalHotel(
    selectedHotelId,
    {
      startDate = '',
      endDate = '',
      page = 1,
      per_page = 10,
      id = '',
    }: { startDate: string; endDate: string; page: number; per_page: number; id: string }
  ) {
    const response: ListHotelWithdrawalsDto = await axiosInstance.get(
      `${urlAdmin}/hotels/${selectedHotelId}/marketplace/withdrawals`,
      {
        params: {
          startDate,
          endDate,
          page,
          per_page,
          id,
        },
      }
    );
    return response;
  },

  getHotelWithdrawalByIdAdminHotel(selectedHotelId: string, id: string) {
    if (selectedHotelId) {
      return axiosInstance.get(`${urlAdmin}/hotels/${selectedHotelId}/marketplace/withdrawals/${id}`);
    }
  },
  calculateWithdrawal(selectedHotelId: string, data) {
    if (selectedHotelId) {
      return axiosInstance.post(`${urlAdmin}/hotels/${selectedHotelId}/marketplace/withdrawals/calculate`, data);
    }
  },
  createWithdrawal(selectedHotelId: string, data) {
    if (selectedHotelId) {
      return axiosInstance.post(`${urlAdmin}/hotels/${selectedHotelId}/marketplace/withdrawals`, data);
    }
  },
  getBankAccount(selectedHotelId: string) {
    if (selectedHotelId) {
      return axiosInstance.get(`${urlAdmin}/hotels/${selectedHotelId}/settings/bank-account`);
    }
  },
};

export type MarketplaceWithdrawalService = typeof marketplaceWithdrawalService;
