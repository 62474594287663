import React, { useEffect } from 'react';
import Sidebar from '@components/Sidebar/Sidebar';
import { Layout, Spin, Row } from 'antd';
import { Switch, Route, useLocation, useHistory, withRouter } from 'react-router-dom';
import { superAdminRoutes, AppRoutes } from '@constants/app-routes';
import { superAdminMenus, adminMenus, AppMenu } from '@constants/app-menus';
import { useAuth } from '@context/auth-context';
import { useApp } from '@context/app-context';
import Header from '@components/Header';
import { Helmet } from 'react-helmet';
import { useMenus } from '@hooks/use-menus';
import * as Sentry from '@sentry/react';
import { WEB_SERVICES } from '@services/index';
import { useService } from '@hooks/use-service';
import { GetHotelProfileResponseDto } from '@libs/dto';
import withClearCache from './clearCache';

const SentryRoute = Sentry.withSentryRouting(Route);

const Login = React.lazy(() => import('@packages/Login'));

const Spinner = () => (
  <Layout style={{ placeContent: 'center', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <Row align="middle" justify="center">
      <Spin size="large" />
    </Row>
  </Layout>
);

const ClearCacheComponent = withClearCache(MainApp);

function App(props) {
  return <ClearCacheComponent {...props} />;
}

function MainApp() {
  const { identity } = useAuth();
  const { selectedHotelId, selectHotel } = useApp();
  const routes: AppRoutes = superAdminRoutes;

  const sidebarMenus: AppMenu = useMenus(adminMenus, superAdminMenus);
  const baseSiteName = process.env.REACT_APP_BASE_SITE_NAME || 'Admin';

  const hotelService = useService(WEB_SERVICES.Hotel);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname.search('null') !== -1 && selectedHotelId) {
      history.push(`/hotels/${selectedHotelId}/dashboard`);
    } else if (location.pathname.search('null') !== -1 && !selectedHotelId) {
      history.push(`/dashboard`);
    }
    //eslint-disable-next-line
  }, [location, history, selectedHotelId]);

  useEffect(() => {
    const isAdminHotel = window.location.pathname.split('/')?.[1] === 'hotels' ? true : false;
    const hotelId = isAdminHotel ? window.location.pathname.split('/')?.[2] : '';

    if (!location.pathname.includes('/hotels/') && !selectedHotelId) {
      selectHotel(null, null, null);
      return;
    }
    if (location.pathname.includes('/hotels/') && !selectedHotelId) {
      selectHotel(null, null, null);
      history.push(`/dashboard`);
      return;
    }
    if (location.pathname.includes('/hotels/') && selectedHotelId && isAdminHotel && hotelId !== selectedHotelId) {
      hotelService
        .query<Promise<GetHotelProfileResponseDto>>('getHotelProfile', [hotelId])
        .then((res) => {
          selectHotel(res?.data?.id, res?.data?.propertyName, res?.data);
          // history.push(window.location.pathname);
        })
        .catch((_error) => {
          window.location.href = '/forbidden';
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {identity ? (
        <Layout style={{ minHeight: '100vh' }}>
          <Helmet>
            <title>{baseSiteName}</title>
          </Helmet>
          <Sidebar menus={sidebarMenus} />
          <Layout className="site-layout">
            <Header />
            <React.Suspense fallback={<Spinner />}>
              <Switch>
                {routes.map((route) => (
                  <SentryRoute {...route} key={route.id} />
                ))}
              </Switch>
            </React.Suspense>
          </Layout>
        </Layout>
      ) : (
        <React.Fragment>
          <Helmet>
            <title>{baseSiteName}</title>
          </Helmet>
          <React.Suspense fallback={<Spinner />}>
            <Login />
          </React.Suspense>
        </React.Fragment>
      )}
    </>
  );
}

export default withRouter(App);
