import { axiosInstance } from '@config/axios-instance-v2';
import { FoodBannerAddDto } from '@libs/dto';

const url = `/admin/hotels`;
export const foodBannerService = {
  list(hotel_id: number, { page = '1', per_page = '10' }: { page: string; per_page: string }) {
    return axiosInstance.get(`${url}/${hotel_id}/foods/settings/banners`, {
      params: { page, per_page },
    });
  },

  create(hotel_id, data: FoodBannerAddDto) {
    return axiosInstance.post(`${url}/${hotel_id}/foods/settings/banners`, data);
  },
  update(hotel_id, id: string, data: FoodBannerAddDto) {
    return axiosInstance.put(`${url}/${hotel_id}/foods/settings/banners/${id}`, data);
  },
  delete(hotel_id: number, id: string) {
    return axiosInstance.delete(`${url}/${hotel_id}/foods/settings/banners/${id}`);
  },
};

export type FoodBannerService = typeof foodBannerService;
