export enum SouvenirOrderStatusEnum {
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  ON_HOLD = 'ON_HOLD',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
  PAID = 'PAID',
}
