import { axiosInstance } from '@config/axios-instance-v2';
import { CreateVoucherDto, VoucherDetailDto, VoucherListDto } from '../libs/dto';

export const url = `/admin`;

export const voucherService = {
  async list(hotel_id, { page = 1, per_page = 10 }: { page: number; per_page: number }) {
    if (hotel_id) {
      const response: VoucherListDto = await axiosInstance.get(`${url}/hotels/${hotel_id}/vouchers`, {
        params: {
          page,
          per_page,
        },
      });
      return response;
    }
    const response: VoucherListDto = await axiosInstance.get(`${url}/vouchers`, {
      params: {
        page,
        per_page,
      },
    });
    return response;
  },
  async get(hotel_id, id: string) {
    if (hotel_id) {
      const response: VoucherDetailDto = await axiosInstance.get(`${url}/hotels/${hotel_id}/vouchers/${id}`, {});
      return response;
    }
    const response: VoucherDetailDto = await axiosInstance.get(`${url}/vouchers/${id}`, {});
    return response;
  },
  async create(hotel_id, data: CreateVoucherDto) {
    if (hotel_id) {
      const response = await axiosInstance.post(`${url}/hotels/${hotel_id}/vouchers`, data, {});
      return response;
    }
    const response = await axiosInstance.post(`${url}/vouchers`, data, {});
    return response;
  },
  async update(hotel_id, id: string, data: CreateVoucherDto) {
    if (hotel_id) {
      const response = await axiosInstance.put(`${url}/hotels/${hotel_id}/vouchers/${id}`, data, {});
      return response;
    }
    const response = axiosInstance.put(`${url}/vouchers/${id}`, data, {});
    return response;
  },
  async delete(hotel_id: string, id: string) {
    if (hotel_id) {
      const response = await axiosInstance.delete(`${url}/hotels/${hotel_id}/vouchers/${id}`, {});
      return response;
    }
    const response = await axiosInstance.delete(`${url}/vouchers/${id}`, {});
    return response;
  },
};
export type VoucherService = typeof voucherService;
