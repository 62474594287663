import { ArrowLeftOutlined } from '@ant-design/icons';
import ContentHelmet from '@components/common/ContentHelmet';
import ContentLayout from '@components/common/ContentLayout';
import { useNotif } from '@hooks/use-notif';
import { PayloadCreateRoleDto } from '@libs/dto';
import { ActionsEnum, SubjectsEnum } from '@libs/enums';
import { adminService } from '@services/admin';
import { permissions as permissionsRole } from '@utils/permission';
import { Button, Checkbox, Col, Divider, Form, Input, Row, Spin, Typography } from 'antd';
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';
import './Add.scss';

const { TextArea } = Input;

const CreateRole = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { addSuccess } = useNotif();
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [globalChecked, setGlobalChecked] = useState(false);
  const [hotelChecked, setHotelChecked] = useState(false);
  const { data: listPermission, isValidating: loadingListPermission } = useSWR(
    ['listPermission'],
    adminService.listPermission
  );

  const handleCheckAction = (action) => {
    const selectedActionIndex = permissions.findIndex((permission) => permission === action);
    if (selectedActionIndex === -1) {
      setPermissions([...permissions, action]);
    }
    if (selectedActionIndex !== -1) {
      setPermissions(permissions.filter((permission) => permission !== action));
    }
  };

  const handleCheckActions = (checked, actions) => {
    const action = actions.map((action) => action.key);
    const newPermissions = permissions.filter((permission) => action.indexOf(permission) === -1);
    if (checked) {
      setPermissions([...newPermissions, ...action]);
    }
    if (!checked) {
      setPermissions(newPermissions);
    }
  };

  const handleCheckAllPermissions = (checked) => {
    const allGlobalPermissions = listPermission?.data[0].permissions.map((permission) => permission.children);
    const allGlobalActions = allGlobalPermissions.reduce((p, c) => {
      p.push(...c.map((item) => item.key as any));
      return p;
    }, []);
    const allHotelPermissions = listPermission?.data[1].permissions.map((permission) => permission.children);
    const allHotelActions = allHotelPermissions.reduce((p, c) => {
      p.push(...c.map((item) => item.key as any));
      return p;
    }, []);
    if (checked) {
      setPermissions([...allGlobalActions, ...allHotelActions]);
    }
    if (!checked) {
      setPermissions([]);
    }
    setAllChecked(checked);
    setHotelChecked(false);
    setGlobalChecked(false);
  };

  const handleCheckGlobalPermissions = (checked) => {
    const allPermissions = listPermission?.data[0].permissions.map((permission) => permission.children);
    const allActions = allPermissions.reduce((p, c) => {
      p.push(...c.map((item) => item.key as any));
      return p;
    }, []);
    if (checked) {
      setPermissions(allActions);
    }
    if (!checked) {
      setPermissions([]);
    }
    setAllChecked(false);
    setHotelChecked(false);
    setGlobalChecked(checked);
  };

  const handleCheckHotelPermissions = (checked) => {
    const allPermissions = listPermission?.data[1].permissions.map((permission) => permission.children);
    const allActions = allPermissions.reduce((p, c) => {
      p.push(...c.map((item) => item.key as any));
      return p;
    }, []);
    if (checked) {
      setPermissions(allActions);
    }
    if (!checked) {
      setPermissions([]);
    }
    setAllChecked(false);
    setHotelChecked(checked);
    setGlobalChecked(false);
  };

  const handleAddRole = () => {
    form.validateFields().then(async (value) => {
      setLoading(true);
      const data: PayloadCreateRoleDto = {
        name: value.name,
        description: value.description,
        permissions: permissions,
      };
      try {
        await adminService.createRole(data);
        setLoading(false);
        addSuccess('Successfully Added Role');
        history.goBack();
      } catch (error) {
        setLoading(false);
      }
    });
  };

  return (
    <ContentHelmet title="Settings - Add Role">
      <ContentLayout
        BreadcrumbItems={[
          { href: '/', title: 'Home' },
          {
            href: `/settings?tab=roles-and-permissions`,
            title: 'Settings',
          },
          { href: window.location.pathname, title: `Add Role` },
        ]}
        loading={loadingListPermission}
      >
        <Spin spinning={loadingListPermission || loading}>
          <div className="header-add-role">
            <ArrowLeftOutlined style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => history.goBack()} />
            <Typography style={{ fontSize: 16, fontWeight: 600, marginLeft: 18 }}>Add Role</Typography>
          </div>
          <Divider style={{ margin: 0 }} />
          <div style={{ padding: '24px' }}>
            <Typography style={{ fontSize: 16, fontWeight: 600, marginBottom: 24 }}>Role</Typography>
            <Form layout="vertical" form={form}>
              <Form.Item
                rules={[{ required: true, message: 'Tenant name is required.' }]}
                label="Role Name"
                name="name"
              >
                <Input
                  placeholder="Input role name"
                  disabled={!permissionsRole(ActionsEnum.CREATE, SubjectsEnum.ROLE)}
                />
              </Form.Item>
              <Form.Item label="Role Description" name="description">
                <TextArea
                  rows={4}
                  placeholder="Input role description"
                  disabled={!permissionsRole(ActionsEnum.CREATE, SubjectsEnum.ROLE)}
                />
              </Form.Item>
            </Form>
            <div className="divider-role">
              <Divider />
            </div>
            <Typography style={{ fontSize: 16, fontWeight: 600, marginBottom: 24 }}>Permissions</Typography>
            <div style={{ marginBottom: 24 }}>
              <Checkbox
                onChange={(event) => handleCheckAllPermissions(event.target.checked)}
                checked={allChecked}
                disabled={!permissionsRole(ActionsEnum.CREATE, SubjectsEnum.ROLE)}
              >
                All Permissions (Global & Hotel)
              </Checkbox>
              <Checkbox
                onChange={(event) => handleCheckGlobalPermissions(event.target.checked)}
                checked={globalChecked}
                disabled={!permissionsRole(ActionsEnum.CREATE, SubjectsEnum.ROLE)}
              >
                Only Global Permissions
              </Checkbox>
              <Checkbox
                onChange={(event) => handleCheckHotelPermissions(event.target.checked)}
                checked={hotelChecked}
                disabled={!permissionsRole(ActionsEnum.CREATE, SubjectsEnum.ROLE)}
              >
                Only Hotel Permissions
              </Checkbox>
            </div>
            <div className="divider-role">
              <Divider />
            </div>
            <Row gutter={[8, 32]}>
              {listPermission?.data.map((group, index) => (
                <Fragment>
                  <Col className={`setting-role-form-permission-normal`} span={24}>
                    <Typography style={{ fontSize: 14, fontWeight: 600, marginBottom: 24 }}>{group.label}</Typography>
                    <Row gutter={[8, 32]}>
                      {group.permissions.map((permission) => {
                        const actions = permission?.children?.map((action) => action.key);
                        const isChecked = actions.filter((action) => permissions.indexOf(action) === -1);
                        return (
                          <Col span={6}>
                            <Checkbox
                              onChange={(event) => handleCheckActions(event.target.checked, permission.children)}
                              indeterminate={isChecked.length > 0 && isChecked.length < actions.length}
                              checked={isChecked.length < 1}
                              disabled={!permissionsRole(ActionsEnum.CREATE, SubjectsEnum.ROLE)}
                            >
                              {permission.title}
                            </Checkbox>
                            {permission?.children?.map((action) => {
                              const isChecked = permissions.indexOf(action.key) !== -1;
                              return (
                                <div className="setting-role-form-permission-item">
                                  <Checkbox
                                    onChange={() => handleCheckAction(action.key)}
                                    value={action.key}
                                    checked={isChecked}
                                    disabled={!permissionsRole(ActionsEnum.CREATE, SubjectsEnum.ROLE)}
                                  >
                                    {action.title}
                                  </Checkbox>
                                </div>
                              );
                            })}
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                  {listPermission?.data.length - 1 === index + 1 && <Divider style={{ margin: 0 }} />}
                </Fragment>
              ))}
            </Row>
            <Form layout="vertical" form={form} onFinish={handleAddRole}>
              <Form.Item style={{ textAlign: 'end', marginBottom: 0, marginTop: 24 }}>
                <Button
                  id="button-save"
                  type="primary"
                  htmlType="submit"
                  disabled={!permissionsRole(ActionsEnum.CREATE, SubjectsEnum.ROLE)}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </ContentLayout>
    </ContentHelmet>
  );
};

export default CreateRole;
