import React from 'react';
import { Modal, Form, Input } from 'antd';
import { WEB_SERVICES } from '@services/index';
import { useNotif } from '@hooks/use-notif';
import { useService } from '@hooks/use-service';
import { AdminService } from '@services/admin';
import { AdminProfile } from '@libs/dto/admin';

export default function ChangePassword({ visible, setVisible }) {
  const adminService = useService<AdminService>(WEB_SERVICES.Admin);
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const { addError, addSuccess } = useNotif();

  const handleOk = () => {
    setLoading(true);

    form.validateFields().then(async (values) => {
      try {
        const uat: AdminProfile = JSON.parse(localStorage.getItem('uat') || '{}');
        await adminService.query('changePassword', [
          {
            ...values,
            id: uat.id,
          },
        ]);
        addSuccess('Berhasil mengganti Password!');
        form.resetFields();
      } catch (error) {
        addError(error.response.data);
      } finally {
        setVisible(false);
        setLoading(false);
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <>
      <Modal title="Change Password" visible={visible} onOk={handleOk} confirmLoading={loading} onCancel={handleCancel}>
        <Form form={form}>
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[{ required: true, message: 'Please input your Old Password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[{ required: true, message: 'Please input your New Password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[{ required: true, message: 'Please Confirm Password!' }]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
