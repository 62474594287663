import { axiosInstance } from '@config/axios-instance-v2';
import { AddTagsDto, UpdateTagsDto } from '@libs/dto/cms';

const url = '/admin/cms/tags';

export const cmsTagsService = {
  list({ page = '1', per_page = '10' }: { page: string; per_page: string }) {
    return axiosInstance.get(`${url}`, {
      params: {
        page,
        per_page,
      },
    });
  },
  get(id: string) {
    return axiosInstance.get(`${url}/${id}`);
  },
  create(data: AddTagsDto) {
    return axiosInstance.post(`${url}`, data);
  },
  update(id: string, data: UpdateTagsDto) {
    return axiosInstance.put(`${url}/${id}`, data);
  },
  delete(id: string) {
    return axiosInstance.delete(`${url}/${id}`);
  },
};

export type CmsTagsService = typeof cmsTagsService;
