import { axiosInstance } from '@config/axios-instance-v2';
import {
  FoodStatusUpdateDto,
  PaymentConfigureFoodResponseDto,
  PaymentConfigureFoodDto,
  FoodDetailDto,
  FoodListDto,
  FoodAddEditPayloadDto,
} from '@libs/dto/food';

const url = `/admin/hotels`;
const urlFoods = '/admin/foods';

export const foodService = {
  async list(
    hotel_id: string,
    {
      page = '1',
      per_page = '10',
      status = '',
      search = '',
    }: { page: string; per_page: string; status: string; search: string }
  ) {
    const response: FoodListDto = await axiosInstance.get(`${url}/${hotel_id}/foods/settings/foods`, {
      params: { page, per_page, status, search },
    });
    return response;
  },
  async get(hotel_id: string, id: string) {
    const response: FoodDetailDto = await axiosInstance.get(`${url}/${hotel_id}/foods/settings/foods/${id}`);
    return response;
  },
  async create(hotel_id: string, data: FoodAddEditPayloadDto) {
    const response = await axiosInstance.post(`${url}/${hotel_id}/foods/settings/foods/`, data);
    return response;
  },
  async update(hotel_id: string, id: string, data: FoodAddEditPayloadDto) {
    const response = await axiosInstance.put(`${url}/${hotel_id}/foods/settings/foods/${id}`, data);
    return response;
  },
  async delete(hotel_id: string, id: string) {
    const response = await axiosInstance.delete(`${url}/${hotel_id}/foods/settings/foods/${id}`);
    return response;
  },
  async listHotelWithFoodFeature({ page = '1', per_page = '10' }: { page: string; per_page: string }) {
    return axiosInstance.get(`/admin/foods/settings/hotels`, {
      params: { page, per_page },
    });
  },
  updateFoodStatus(data: FoodStatusUpdateDto) {
    return axiosInstance.put(`/admin/foods/settings/hotels/bulk-modify-feature-status`, data);
  },
  async getPaymentConfigureFood() {
    const response: PaymentConfigureFoodResponseDto = await axiosInstance.get(
      `${urlFoods}/settings/payment-configuration`
    );
    return response;
  },
  async updatePaymentConfigureFood(data: PaymentConfigureFoodDto) {
    const response = await axiosInstance.put(`${urlFoods}/settings/payment-configuration`, data, {});
    return response;
  },
};
export type FoodService = typeof foodService;
