export const upperSnakeCaseToTitle = (string = '') => {
  if (!string) {
    return '-';
  }
  return string
    .replaceAll('_', ' ')
    .split(' ')
    .map((word) => word[0]?.toUpperCase() + word.slice(1)?.toLowerCase())
    .join(' ');
};
