/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { AdminProfile, AdminSignInDto, AdminAndTokenDto } from '@libs/dto/admin';
import { useService } from '@hooks/use-service';
import { AdminService } from '@services/admin';
import { WEB_SERVICES } from '@services/index';
import { useHistory } from 'react-router-dom';
import { Layout, Spin, Row } from 'antd';
import { useNotif } from '@hooks/use-notif';
import { redirectHotelAdmin, redirectSuperAdmin } from '@utils/permission';

interface AuthContextState {
  isSubmitted: boolean;
  isLoading: boolean;
  identity: AdminProfile | null;
  token: string;
  isError: boolean;
  layout: string;
}

export interface AuthContextValue {
  identity: AdminProfile | null;
  signIn(credential: AdminSignInDto): void;
  signOut(): void;
  isLoading: boolean;
  token: string;
  isSubmitted: boolean;
  isError: boolean;
  layout: string;
}

const Spinner = () => (
  <Layout style={{ placeContent: 'center', height: '100vh' }}>
    <Row align="middle" justify="center">
      <Spin size="large" />
    </Row>
  </Layout>
);

const AuthContext = React.createContext<AuthContextValue>({} as never);

export default function AuthContextProvider({ children }) {
  const { addError } = useNotif();
  const [{ identity, isLoading, token, isSubmitted, isError, layout }, updateState] = useState<AuthContextState>({
    isLoading: true,
    identity: null,
    token: localStorage.getItem('selectedHotelId'),
    isSubmitted: false,
    isError: false,
    layout: null,
  });
  const adminService = useService<AdminService>(WEB_SERVICES.Admin);

  const history = useHistory();

  const setToken = (_token: string) => {
    updateState((prevState) => ({
      ...prevState,
      token: _token,
    }));
  };

  const setIdentity = (profile: AdminProfile | null) => {
    updateState((prevState) => ({
      ...prevState,
      identity: profile,
    }));
  };

  const setLayout = (layout: string) => {
    updateState((prevState) => ({
      ...prevState,
      layout: layout,
    }));
  };

  const setLoading = (state: boolean) => {
    updateState((prevState) => ({
      ...prevState,
      isLoading: state,
    }));
  };

  const setSubmit = (state: boolean) => {
    updateState((prevState) => ({
      ...prevState,
      isSubmitted: state,
    }));
  };

  const setError = (state: boolean) => {
    updateState((prevState) => ({
      ...prevState,
      isError: state,
    }));
  };

  const signIn = (credential: AdminSignInDto) => {
    setSubmit(true);
    adminService
      .query<Promise<AdminAndTokenDto>>('signIn', [credential])
      .then((res) => {
        const { admin, layout } = res.data;
        // eslint-disable-next-line no-underscore-dangle
        const _token = res.data.token;
        localStorage.setItem('token', _token);
        localStorage.setItem('layout', layout);
        localStorage.setItem('uat', JSON.stringify(admin));
        setSubmit(false);

        if (admin.isSuperAdmin) {
          window.location.href = '/dashboard';
          return;
        }
        if (!admin.isSuperAdmin && layout === 'SUPER_ADMIN') {
          redirectSuperAdmin();
        }
        if (!admin.isSuperAdmin && layout === 'HOTEL_ADMIN') {
          localStorage.setItem('selectedHotelId', admin.hotels[0]?.id);
          localStorage.setItem('selectedHotelName', admin.hotels[0]?.name);
          localStorage.setItem('selectedHotel', JSON.stringify(admin.hotels[0]));
          redirectHotelAdmin(admin.hotels[0]);
          return;
        }
      })
      .catch((error) => {
        setSubmit(false);
        if (error) {
          setError(true);
        } else {
          setError(false);
        }
      });
  };

  const signOut = () => {
    setIdentity(null);
    history.replace('/');
    const dataLocalStorage = [
      'selectedHotelName',
      'selectedHotelId',
      'selectedHotel',
      'token',
      'layout',
      'uat',
      'hotelName',
    ];
    dataLocalStorage.forEach((res) => {
      localStorage.removeItem(res);
    });
  };

  useEffect(() => {
    async function loadUserData() {
      try {
        const adminToken = localStorage.getItem('token');
        const adminProfile: AdminProfile = localStorage.getItem('uat') && JSON.parse(localStorage.getItem('uat'));
        const adminLayout = localStorage.getItem('layout');
        if (adminToken && adminProfile) {
          setIdentity(adminProfile);
          setToken(adminToken);
          setLayout(adminLayout);
        } else {
          setToken(null);
          setIdentity(null);
          setLayout(null);
          history.replace('/');
        }
      } catch (error) {
        addError(error);
        localStorage.clear();
      } finally {
        setLoading(false);
      }
    }

    loadUserData();
    //eslint-disable-next-line
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider value={{ identity, signIn, signOut, isLoading, token, isSubmitted, isError, layout }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
