import { axiosInstance } from '@config/axios-instance-v2';
import {
  AddCustomerPointDto,
  GuestBalanceListDto,
  CustomerDetailDto,
  GuestLedgerListDto,
  CustomerListDto,
} from '@libs/dto';

const url = '/admin/settings/guests';
const urlHotel = '/admin/hotels';
const urlGuest = '/admin';

export const guestService = {
  async listUser({ page, per_page }: { page: string; per_page: string }) {
    return axiosInstance.get(`/admin/users`, {
      params: {
        page,
        per_page,
      },
    });
  },
  async listGuest(
    hotel_id,
    {
      page = 1,
      per_page = 10,
      name = '',
      email = '',
      sort_by = 'name',
      sort = 'desc',
    }: { page: number; per_page: number; name: string; email: string; sort_by: string; sort: string }
  ) {
    if (hotel_id) {
      const response: CustomerListDto = await axiosInstance.get(`${urlGuest}/hotels/${hotel_id}/guests`, {
        params: {
          page,
          per_page,
          name,
          email,
          sort_by,
          sort,
        },
      });
      return response;
    }
    const response: CustomerListDto = await axiosInstance.get(`${urlGuest}/guests`, {
      params: {
        page,
        per_page,
        name,
        email,
        sort_by,
        sort,
      },
    });
    return response;
  },
  async getDetailGuest(hotel_id: string, id: string) {
    if (hotel_id) {
      const response: CustomerDetailDto = await axiosInstance.get(`${urlGuest}/hotels/${hotel_id}/guests/${id}`, {});
      return response;
    }
    const response: CustomerDetailDto = await axiosInstance.get(`${urlGuest}/guests/${id}`, {});
    return response;
  },
  async addPoint(id: string, data: AddCustomerPointDto) {
    const response = await axiosInstance.post(`${urlGuest}/guests/${id}/points`, data, {});
    return response;
  },
  async listGuestLedger(
    id: string,
    {
      page = '1',
      per_page = '10',
      mutationType = '',
      event = '',
    }: {
      page: string;
      per_page: string;
      mutationType: string;
      event: string;
    }
  ) {
    const response: GuestLedgerListDto = await axiosInstance.get(`${urlGuest}/guests/${id}/points`, {
      params: {
        page,
        per_page,
        mutationType,
        event,
      },
    });
    return response;
  },
  async listGuestBalance(
    id: string,
    {
      page = '1',
      per_page = '10',
      event = '',
    }: {
      page: string;
      per_page: string;
      event: string;
    }
  ) {
    const response: GuestBalanceListDto = await axiosInstance.get(`${urlGuest}/guests/${id}/wallets/mutations`, {
      params: {
        page,
        per_page,
        event,
      },
    });
    return response;
  },
  get(id: string) {
    return axiosInstance.get(`${url}/${id}`);
  },
  listGuestHotel(hotel_id: string, { page = '1', per_page = '10' }: { page: string; per_page: string }) {
    return axiosInstance.get(`${urlHotel}/${hotel_id}/settings/guests`, {
      params: {
        page,
        per_page,
      },
    });
  },
};

export type GuestService = typeof guestService;
