import { axiosInstance } from '@config/axios-instance-v2';
import {
  ReportRoomBookingDto,
  ReportRoomCheckinDto,
  ReportRoomRevenueDto,
  ReportRoomTopTransactionDto,
} from '@libs/dto';

const url = '/admin';

export const dashboardRoomService = {
  async getTotalRevenue(selectedHotelId, { startDate, endDate }: { startDate: string; endDate: string }) {
    if (selectedHotelId) {
      const response: ReportRoomRevenueDto = await axiosInstance.get(
        `${url}/hotels/${selectedHotelId}/rooms/reports/total-revenue`,
        {
          params: {
            startDate,
            endDate,
          },
        }
      );
      return response;
    } else {
      const response: ReportRoomRevenueDto = await axiosInstance.get(`${url}/rooms/reports/total-revenue`, {
        params: {
          startDate,
          endDate,
        },
      });
      return response;
    }
  },
  async getTotalBooking(selectedHotelId, { startDate, endDate }: { startDate: string; endDate: string }) {
    if (selectedHotelId) {
      const response: ReportRoomBookingDto = await axiosInstance.get(
        `${url}/hotels/${selectedHotelId}/rooms/reports/total-booking`,
        {
          params: {
            startDate,
            endDate,
          },
        }
      );
      return response;
    } else {
      const response: ReportRoomBookingDto = await axiosInstance.get(`${url}/rooms/reports/total-booking`, {
        params: {
          startDate,
          endDate,
        },
      });
      return response;
    }
  },
  async getTotalCheckin(selectedHotelId, { startDate, endDate }: { startDate: string; endDate: string }) {
    if (selectedHotelId) {
      const response: ReportRoomCheckinDto = await axiosInstance.get(
        `${url}/hotels/${selectedHotelId}/rooms/reports/total-checkin`,
        {
          params: {
            startDate,
            endDate,
          },
        }
      );
      return response;
    } else {
      const response: ReportRoomCheckinDto = await axiosInstance.get(`${url}/rooms/reports/total-checkin`, {
        params: {
          startDate,
          endDate,
        },
      });
      return response;
    }
  },

  async getTopTransaction(
    selectedHotelId,
    topBy,
    {
      startDate = '',
      endDate = '',
      orderBy = 'desc',
      sortBy = 'totalPrice',
    }: { startDate: string; endDate: string; orderBy: string; sortBy: string }
  ) {
    if (selectedHotelId) {
      const response: ReportRoomTopTransactionDto = await axiosInstance.get(
        `${url}/hotels/${selectedHotelId}/rooms/reports/top-transaction`,
        {
          params: {
            startDate,
            endDate,
            orderBy,
            sortBy,
          },
        }
      );
      return response;
    } else {
      const response: ReportRoomTopTransactionDto = await axiosInstance.get(
        `${url}/rooms/reports/top-transaction/${topBy}`,
        {
          params: {
            startDate,
            endDate,
            orderBy,
            sortBy,
          },
        }
      );
      return response;
    }
  },
};

export type DashboardRoomService = typeof dashboardRoomService;
