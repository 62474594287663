import { axiosInstance } from '@config/axios-instance-v2';
import { ArticleAddDto } from '@libs/dto';

const url = `/admin/cms/menus`;
export const cmsMenusService = {
  list({ page = '1', per_page = '10' }: { page: string; per_page: string }) {
    return axiosInstance.get(`${url}`, {
      params: { page, per_page },
    });
  },
  listArticle({ page = '1', per_page = '10' }: { page: string; per_page: string }) {
    return axiosInstance.get(`/admin/cms/articles`, {
      params: { page, per_page },
    });
  },
  get(id: string) {
    return axiosInstance.get(`${url}/${id}`);
  },
  create(data: ArticleAddDto) {
    return axiosInstance.post(`${url}`, data);
  },
  update(id: string, data: ArticleAddDto) {
    return axiosInstance.put(`${url}/${id}`, data);
  },
  delete(id: string) {
    return axiosInstance.delete(`${url}/${id}`);
  },
};

export type CmsMenusService = typeof cmsMenusService;
