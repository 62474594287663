import { axiosInstance } from '@config/axios-instance-v2';
import { PointConfigurationPayloadDto } from '@libs/dto';

export const url = `/admin/tenant`;

export const pointService = {
  get() {
    return axiosInstance.get(`${url}/point-configuration`, {});
  },
  update(data: PointConfigurationPayloadDto) {
    return axiosInstance.put(`${url}/point-configuration`, data, {});
  },
};
export type PointService = typeof pointService;
