import { axiosInstance } from '@config/axios-instance-v2';
import { BankAccountDto, ListBankAccountDto } from '@libs/dto/bank-account';

const url = '/admin/hotels';

export const bankAccountService = {
  async getHotelBankAccount(selectedHotelId: string) {
    if (selectedHotelId) {
      const response: ListBankAccountDto = await axiosInstance.get(`${url}/${selectedHotelId}/settings/bank-account`);
      return response;
    }
  },
  async addBankAccount(selectedHotelId: string, data: BankAccountDto) {
    const response = await axiosInstance.put(`${url}/${selectedHotelId}/settings/bank-account`, data);
    return response;
  },
};

export type BankAccountService = typeof bankAccountService;
