import React from 'react';
import ContentHelmet from '@components/common/ContentHelmet';
import './BadGateway.scss';
import BadGateway from '@assets/bad-gateway.jpg';

export default function Forbidden() {
  return (
    <ContentHelmet title="Bad Gateway">
      <div className="container-badgateway">
        <h3
          style={{
            color: '#0064D4',
            fontWeight: 'bold',
          }}
        >
          Error 502 Bad Gateway
        </h3>
        <img src={BadGateway} alt="badgateway" className="badgateway" style={{ width: 700 }} />
      </div>
    </ContentHelmet>
  );
}
