import { axiosInstance } from '@config/axios-instance-v2';
import { RoomViewAdd, RoomViewUpdate } from '@libs/dto/room-view';

const url = '/admin/hotels';

export const roomViewService = {
  list(id: string, { page = '1', per_page = '10' }: { page: string; per_page: string }) {
    return axiosInstance.get(`${url}/${id}/settings/room-views`, {
      params: { page, per_page },
    });
  },
  create(id: string, data: RoomViewAdd) {
    return axiosInstance.post(`${url}/${id}/settings/room-views`, data);
  },
  update(id: string, ids: string, data: RoomViewUpdate) {
    return axiosInstance.put(`${url}/${id}/settings/room-views/${ids}`, data);
  },
  delete(id: string, ids: string) {
    return axiosInstance.delete(`${url}/${id}/settings/room-views/${ids}`);
  },
};

export type RoomViewService = typeof roomViewService;
