import React from 'react';
import { Helmet } from 'react-helmet';

export interface ContentHelmetProps {
  title: string;
  description?: string;
}

const ContentHelmet: React.FC<ContentHelmetProps> = ({ title, description, children }) => {
  const baseSiteName = process.env.REACT_APP_BASE_SITE_NAME || 'Admin';

  return (
    <>
      <Helmet>
        <title>{`${title} - ${baseSiteName}` || ''}</title>
        <meta name="description" content={description || `${title} Page`} />
        <link
          rel="icon"
          href={
            process.env.REACT_APP_BASE_SITE_FAVICON ||
            'https://temp-pesanio-bucket.s3.ap-southeast-1.amazonaws.com/98024a42abc3dad975ca09d02-2024-04-17T06%3A35%3A00.470Z'
          }
        />
      </Helmet>
      {children}
    </>
  );
};

export default ContentHelmet;
