import { axiosInstance } from '@config/axios-instance-v2';
import {
  CreateGroupDto,
  CreateLevelDto,
  CreateRewardDto,
  GetDetailGroupDto,
  GetDetailLevelDto,
  ListGroupDto,
  ListLevelDto,
  UpdateGroupDto,
} from '@libs/dto/membership';

const url = '/admin';

export const membershipService = {
  async listGroups({ page = 1, per_page = 10, name = '' }: { page: number; per_page: number; name: string }) {
    const response: ListGroupDto = await axiosInstance.get(`${url}/memberships/settings/groups`, {
      params: {
        page,
        per_page,
        name,
      },
    });
    return response;
  },
  async getGroupDetail(id: string) {
    const response: GetDetailGroupDto = await axiosInstance.get(`${url}/memberships/settings/groups/${id}`);
    return response;
  },
  async createGroup(data: CreateGroupDto) {
    const response = await axiosInstance.post(`${url}/memberships/settings/groups`, data);
    return response;
  },
  async updateGroup(groupId: string, data: UpdateGroupDto) {
    const response = await axiosInstance.put(`${url}/memberships/settings/groups/${groupId}`, data);
    return response;
  },
  deleteGroup(id: string) {
    return axiosInstance.delete(`${url}/memberships/settings/groups/${id}`);
  },
  async listLevel(id: string, { page = 1, per_page = 10, name = '', sort_by = 'level' }) {
    const response: ListLevelDto = await axiosInstance.get(`${url}/memberships/settings/groups/${id}/levels`, {
      params: {
        page,
        per_page,
        name,
        sort_by,
      },
    });
    return response;
  },
  async getLevel(groupId: string, id: string) {
    const response: GetDetailLevelDto = await axiosInstance.get(
      `${url}/memberships/settings/groups/${groupId}/levels/${id}`
    );
    return response;
  },
  async createLevel(groupId: string, data: CreateLevelDto) {
    const response = axiosInstance.post(`${url}/memberships/settings/groups/${groupId}/levels`, data);
    return response;
  },
  async updateLevel(groupId: string, levelId: string, data: CreateLevelDto) {
    const response = await axiosInstance.put(`${url}/memberships/settings/groups/${groupId}/levels/${levelId}`, data);
    return response;
  },
  async deleteLevel(groupId: string, levelId: string) {
    const response = await axiosInstance.delete(`${url}/memberships/settings/groups/${groupId}/levels/${levelId}`);
    return response;
  },
  async listReward(groupId: string, levelId: string, { page = 1, per_page = 10 }) {
    const response: ListLevelDto = await axiosInstance.get(
      `${url}/memberships/settings/groups/${groupId}/levels/${levelId}/rewards`,
      {
        params: {
          page,
          per_page,
        },
      }
    );
    return response;
  },
  async createReward(groupId: string, levelId: string, data: CreateRewardDto) {
    const response = await axiosInstance.post(
      `${url}/memberships/settings/groups/${groupId}/levels/${levelId}/rewards`,
      data
    );
    return response;
  },
  async updateReward(groupId: string, levelId: string, id: string, data: CreateRewardDto) {
    const response = await axiosInstance.put(
      `${url}/memberships/settings/groups/${groupId}/levels/${levelId}/rewards/${id}`,
      data
    );
    return response;
  },
  deleteReward(groupId: string, levelId: string, id: string) {
    return axiosInstance.delete(`${url}/memberships/settings/groups/${groupId}/levels/${levelId}/rewards/${id}`);
  },
};

export type MembershipService = typeof membershipService;
