import { axiosInstance } from '@config/axios-instance-v2';
import { AddShippingChargesDto, UpdateShippingChargesDto } from '@libs/dto';

const url = `/admin/hotels`;

export const shippingChargesService = {
  list(hotel_id, page, pageSize) {
    return axiosInstance.get(`${url}/${hotel_id}/foods/settings/shipping-charges?page=${page}&per_page=${pageSize}`);
  },
  get(hotel_id, id: string) {
    return axiosInstance.get(`${url}/${hotel_id}/foods/settings/shipping-charges/${id}`);
  },
  create(hotel_id, data: AddShippingChargesDto) {
    return axiosInstance.post(`${url}/${hotel_id}/foods/settings/shipping-charges`, data);
  },
  update(hotel_id, id: string, data: UpdateShippingChargesDto) {
    return axiosInstance.put(`${url}/${hotel_id}/foods/settings/shipping-charges/${id}`, data);
  },
  delete(hotel_id: number, id: string) {
    return axiosInstance.delete(`${url}/${hotel_id}/foods/settings/shipping-charges/${id}`);
  },
};
export type ShippingChargesService = typeof shippingChargesService;
