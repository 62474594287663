import { axiosInstance } from '@config/axios-instance-v2';
import { AddRoomFacilityDto } from '@libs/dto';

const url = '/admin/rooms/settings/room-property-facilities';

export const roomFacilityService = {
  list({ page = '1', per_page = '10' }: { page: string; per_page: string }) {
    return axiosInstance.get(`${url}`, {
      params: { page, per_page },
    });
  },
  create(data: AddRoomFacilityDto) {
    return axiosInstance.post(`${url}`, data);
  },
  update(id: string, data: AddRoomFacilityDto) {
    return axiosInstance.put(`${url}/${id}`, data);
  },
  delete(id: string) {
    return axiosInstance.delete(`${url}/${id}`);
  },
};

export type RoomFacilityService = typeof roomFacilityService;
