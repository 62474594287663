import { adminService } from './admin';
import { adminHotelService } from './admin-hotel';
import { availabilityService } from './availability';
import { bedService } from './bed';
import { roomOrderService } from './room-order';
import { cityService } from './city';
import { cmsArticleService } from './cms-articles';
import { cmsCategoriesService } from './cms-categories';
import { cmsMenusService } from './cms-menus';
import { cmsTagsService } from './cms-tags';
import { dashboardAdminService } from './dashboard-admin';
import { foodService } from './food';
import { foodBannerService } from './food-banners';
import { foodCategoriesService } from './food-categories';
import { dashboardFoodService } from './food-dashboard';
import { foodOrderService } from './food-order';
import { guestService } from './guest';
import { hotelService } from './hotel';
import { bannerService } from './hotel-banners';
import { hotelFacilityService } from './hotel-facility';
import { hotelFacilityCategoryService } from './hotel-facility-category';
import { imageService } from './image';
import { marketplaceBalanceService } from './marketplace-balances';
import { marketplaceSettingsService } from './marketplace-settings';
import { marketplaceTopupsService } from './marketplace-topups';
import { marketplaceWithdrawalService } from './marketplace-withdrawals';
import { membershipService } from './membership';
import { paymentChannelService } from './payment-channel';
import { paymentGatewayService } from './payment-gateway';
import { pointService } from './point';
import { promoCodeService } from './promo-code';
import { rateService } from './rate';
import { reportFoodService } from './report-food';
import { reportHotelService } from './report-hotel';
import { roomService } from './room';
import { dashboardRoomService } from './room-dashboard';
import { roomFacilityService } from './room-facility';
import { roomFacilityCategoryService } from './room-facility-category';
import { roomViewService } from './room-view';
import { shippingChargesService } from './shipping-charges';
import { souvenirService } from './souvenir';
import { souvenirBannerService } from './souvenir-banners';
import { souvenirCategoriesService } from './souvenir-categories';
import { dashboardSouvenirService } from './souvenir-dashboard';
import { souvenirOrderService } from './souvenir-order';
import { voucherService } from './voucher';
import { bankAccountService } from './bank-account';
import { cityHotelAdminService } from './getCityHotel';
import { referralService } from './referral';

export const WEB_SERVICES = {
  Admin: adminService,
  City: cityService,
  Hotel: hotelService,
  Room: roomService,
  Guest: guestService,
  Booking: roomOrderService,
  RoomView: roomViewService,
  Bed: bedService,
  HotelFacilityCategory: hotelFacilityCategoryService,
  HotelFacility: hotelFacilityService,
  RoomFacilityCategory: roomFacilityCategoryService,
  RoomFacility: roomFacilityService,
  Image: imageService,
  ReportHotel: reportHotelService,
  Availability: availabilityService,
  Rate: rateService,
  ReportFood: reportFoodService,
  Food: foodService,
  FoodOrder: foodOrderService,
  FoodCategories: foodCategoriesService,
  Souvenir: souvenirService,
  SouvenirCategories: souvenirCategoriesService,
  SouvenirOrder: souvenirOrderService,
  AdminHotel: adminHotelService,
  DashboardAdmin: dashboardAdminService,
  FoodBanners: foodBannerService,
  SouvenirBanners: souvenirBannerService,
  DashboardFoodService: dashboardFoodService,
  ShippingCharges: shippingChargesService,
  Banners: bannerService,
  DashboardSouvenirService: dashboardSouvenirService,
  DashboardRoomService: dashboardRoomService,
  CmsCategoriesService: cmsCategoriesService,
  CmsTagsService: cmsTagsService,
  CmsArticleService: cmsArticleService,
  CmsMenusService: cmsMenusService,
  PromoCode: promoCodeService,
  Voucher: voucherService,
  PaymentGateway: paymentGatewayService,
  PaymentChannel: paymentChannelService,
  PointService: pointService,
  Membership: membershipService,
  MarketplaceBalanceService: marketplaceBalanceService,
  MarketplaceTopupsService: marketplaceTopupsService,
  MarketplaceWithdrawalService: marketplaceWithdrawalService,
  MarketplaceSettings: marketplaceSettingsService,
  BankAccount: bankAccountService,
  CityHotelAdmin: cityHotelAdminService,
  Referral: referralService,
};
