import { axiosInstance } from '@config/axios-instance-v2';
import { ConfigureGatewayDto } from '@libs/dto';

const url = '/admin/payments/gateways';

export const paymentGatewayService = {
  list({ page = '1', perPage = '50' }: { page: string; perPage: string }) {
    return axiosInstance.get(`${url}`, {
      params: {
        page,
        perPage,
      },
    });
  },
  get(name: string) {
    return axiosInstance.get(`${url}/${name}`);
  },
  update(name: string, data: ConfigureGatewayDto) {
    return axiosInstance.put(`${url}/${name}`, data);
  },
};

export type PaymentGatewayService = typeof paymentGatewayService;
