import { axiosInstance } from '@config/axios-instance-v2';

export const cityHotelAdminService = {
  cityHotelAdmin(selectedHotelId: number) {
    let url = `admin/settings/cities?page=1&per_page=10000`;
    if (selectedHotelId) {
      url = `/admin/hotels/${selectedHotelId}/settings/cities?page=1&per_page=10000`;
    }
    return axiosInstance.get(url);
  },
};

export type CityHotelAdmin = typeof cityHotelAdminService;
