/* eslint-disable no-else-return */
/* eslint-disable no-lonely-if */
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';

export function useService<AllMethods>(allMethods: AllMethods) {
  const [state, setState] = React.useState({
    isLoading: false,
    isError: null,
    isRefetch: false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);

  const refetch = () => {
    setState((ps) => ({
      ...ps,
      isRefetch: Math.random(),
    }));
  };

  const setLoading = (loadingState: boolean) => {
    setState((ps) => ({
      ...ps,
      isLoading: loadingState,
    }));
  };

  const dataKeyLength = Object.keys(state).length;

  const IS_SERVER = typeof window === 'undefined';
  const useIsomorphicLayoutEffect = IS_SERVER ? React.useEffect : React.useLayoutEffect;

  type MethodKeys = keyof AllMethods;

  function query<RequestResponse>(
    key: MethodKeys,
    methodParams = [],
    options: {
      dataAlias?: string;
      withEffect?: boolean;
      hookDeps?: unknown[];
    } = {
      withEffect: false,
      hookDeps: [],
    }
  ): RequestResponse & {
    refetch?: () => void;
    [key: string]: unknown;
    mutate?: (value: unknown) => void;
    error?: unknown;
  } {
    const keyString = String(key);
    const effectHooksDeps = options && options.hookDeps ? options.hookDeps : [];
    // Use serviceFnParams to pass service method parameter

    const serviceFnParams = Array.isArray(methodParams) ? [...methodParams] : [];
    if (options && options.withEffect) {
      if (options.dataAlias) {
        // Using dataAlias for aliasing return object

        useIsomorphicLayoutEffect(() => {
          setLoading(true);
          allMethods[keyString](...serviceFnParams)
            .then((response) => {
              setState((ps) => ({
                ...ps,
                [options.dataAlias]: response,
              }));
              setLoading(false);
            })
            .catch((error) => {
              setState((ps) => ({
                ...ps,
                isError: error,
              }));
              setLoading(false);
              return Promise.reject(error);
            });

          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [...effectHooksDeps, state.isRefetch]);

        const dataAliasValue = state[options.dataAlias];
        type StateAliasValue = typeof dataAliasValue;

        return ({
          [options.dataAlias]: state[options.dataAlias],
          refetch,
          error: state.isError,
          mutate(newValue) {
            return setState((ps) => ({
              ...ps,
              [options.dataAlias]: newValue,
            }));
          },
        } as unknown) as RequestResponse & {
          [key: string]: StateAliasValue;
          refetch?: () => void;
          error?: unknown;
          mutate?: (value: unknown) => void;
        };
      } else {
        const dataStateKey = `data${dataKeyLength}`;

        useIsomorphicLayoutEffect(() => {
          setLoading(true);
          allMethods[keyString](...serviceFnParams)
            .then((response) => {
              setState((ps) => ({
                ...ps,
                [dataStateKey]: response,
              }));
              setLoading(false);
            })
            .catch((error) => {
              setState((ps) => ({
                ...ps,
                isError: error,
              }));
              setLoading(false);
              return Promise.reject(error);
            });

          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [...effectHooksDeps, state.isRefetch]);
        return ({
          data: state[`data${dataKeyLength - 1}`],
          refetch,
          error: state.isError,
          mutate: (value: unknown) => {
            return setState((ps) => ({
              ...ps,
              [`data${dataKeyLength - 1}`]: value,
            }));
          },
        } as unknown) as RequestResponse & {
          refetch?: () => void;
          mutate?: (value: unknown) => void;
          error?: unknown;
        };
      }
      // eslint-disable-next-line no-else-return
    } else {
      setLoading(true);
      return allMethods[keyString](...serviceFnParams)
        .then((response) => {
          setLoading(false);
          return Promise.resolve(response);
        })
        .catch((error) => {
          setState({
            ...state,
            isError: error,
          });
          setLoading(false);
          return Promise.reject(error);
        }) as RequestResponse & { refetch: () => void };
      // eslint-disable-next-line no-else-return
    }
  }

  return {
    query,
    isLoading: state.isLoading,
    state,
    setServiceState: (key: string, value: unknown) => {
      return setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    },
  };
}
