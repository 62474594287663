import { axiosInstance } from '@config/axios-instance-v2';
import { GetAvailabilitiesByMonth, UpdateOrInsertDto } from '@libs/dto/availability/availability';

const url = '/admin/hotels';

export const availabilityService = {
  list(data: GetAvailabilitiesByMonth) {
    return axiosInstance.get(`${url}/${data.hotelId}/rooms/availabilities?date=${data.date}&page=1&per_page=100`);
  },
  bulkInsertOrUpdate(id: string, data: UpdateOrInsertDto) {
    return axiosInstance.post(`${url}/${id}/rooms/availabilities/bulk-modify`, data);
  },
};

export type AvailabilityService = typeof availabilityService;
