import { axiosInstance } from '@config/axios-instance-v2';
import { FoodDetailOrderDto } from '@libs/dto/food-order';

const url = 'admin/hotels';
const baseUrl = 'admin';

export const foodOrderService = {
  list(
    selectedHotelId: string,
    {
      page = '1',
      per_page = '10',
      start_date = '',
      end_date = '',
      status = '',
      hotelName = '',
      deliveryMethod = '',
      orderBy = '',
      orderId = '',
      guestName = '',
      guestEmail = '',
    }: {
      page: string;
      per_page: string;
      start_date?: string;
      end_date?: string;
      status?: string;
      hotelName?: string;
      deliveryMethod?: string;
      orderBy?: string;
      orderId?: string;
      guestName?: string;
      guestEmail?: string;
    }
  ) {
    if (selectedHotelId) {
      return axiosInstance.get(`${url}/${selectedHotelId}/foods/orders`, {
        params: {
          page,
          per_page,
          start_date,
          end_date,
          status,
          deliveryMethod,
          orderBy,
          orderId,
          guestName,
          guestEmail,
        },
      });
    } else {
      return axiosInstance.get(`${baseUrl}/foods/orders`, {
        params: {
          page,
          per_page,
          start_date,
          end_date,
          status,
          hotelName,
          deliveryMethod,
          orderBy,
          orderId,
          guestName,
          guestEmail,
        },
      });
    }
  },
  async get(id: string, selectedHotelId: String = null) {
    if (selectedHotelId) {
      const response: FoodDetailOrderDto = await axiosInstance.get(`${url}/${selectedHotelId}/foods/orders/${id}`);
      return response;
    } else {
      const response: FoodDetailOrderDto = await axiosInstance.get(`/admin/foods/orders/${id}`);
      return response;
    }
  },
  async pendingFoodOrder(id: string, selectedHotelId: String) {
    if (selectedHotelId) {
      const response = await axiosInstance.put(`${url}/${selectedHotelId}/foods/orders/${id}/status-pending`);
      return response;
    } else {
      const response = await axiosInstance.put(`${baseUrl}/foods/orders/${id}/status-pending`);
      return response;
    }
  },
  async proccessFoodOrder(id: string, selectedHotelId: String) {
    if (selectedHotelId) {
      const response = await axiosInstance.put(`${url}/${selectedHotelId}/foods/orders/${id}/status-proccess`);
      return response;
    } else {
      const response = await axiosInstance.put(`${baseUrl}/foods/orders/${id}/status-proccess`);
      return response;
    }
  },
  async onHoldFoodOrder(id: string, selectedHotelId: String) {
    if (selectedHotelId) {
      const response = await axiosInstance.put(`${url}/${selectedHotelId}/foods/orders/${id}/status-onhold`);
      return response;
    } else {
      const response = await axiosInstance.put(`${baseUrl}/foods/orders/${id}/status-onhold`);
      return response;
    }
  },
  async cancelFoodOrder(id: string, selectedHotelId: String) {
    if (selectedHotelId) {
      const response = await axiosInstance.put(`${url}/${selectedHotelId}/foods/orders/${id}/status-cancel`);
      return response;
    } else {
      const response = await axiosInstance.put(`${baseUrl}/foods/orders/${id}/status-cancel`);
      return response;
    }
  },
  async completeFoodOrder(id: string, selectedHotelId: String) {
    if (selectedHotelId) {
      const response = await axiosInstance.put(`${url}/${selectedHotelId}/foods/orders/${id}/status-complete`);
      return response;
    } else {
      const response = await axiosInstance.put(`${baseUrl}/foods/orders/${id}/status-complete`);
      return response;
    }
  },
  async refundFoodOrder(id: string, selectedHotelId: String) {
    if (selectedHotelId) {
      const response = await axiosInstance.put(`${url}/${selectedHotelId}/foods/orders/${id}/status-refund`);
      return response;
    } else {
      const response = await axiosInstance.put(`${baseUrl}/foods/orders/${id}/status-refund`);
      return response;
    }
  },
  async expiredFoodOrder(id: string, selectedHotelId: String) {
    if (selectedHotelId) {
      const response = await axiosInstance.put(`${url}/${selectedHotelId}/foods/orders/${id}/status-expired`);
      return response;
    } else {
      const response = await axiosInstance.put(`${baseUrl}/foods/orders/${id}/status-expired`);
      return response;
    }
  },
};

export type FoodOrderService = typeof foodOrderService;
