import { axiosInstance } from '@config/axios-instance-v2';
import { ReferralConfigurationPayloadDto } from '@libs/dto';

export const url = `/admin/tenant`;

export const referralService = {
  getListRefferals(page = '1', per_page = '10', guestName = '', referralCode = '') {
    return axiosInstance.get(`${url}/referrals`, {
      params: {
        page,
        per_page,
        guestName,
        referralCode,
      },
    });
  },
  getReferralConfigure() {
    return axiosInstance.get(`${url}/referral-configuration`, {});
  },
  updateReferralConfigure(data: ReferralConfigurationPayloadDto) {
    return axiosInstance.put(`${url}/referral-configuration`, data);
  },
};
export type ReferralService = typeof referralService;
