import { axiosInstance } from '@config/axios-instance-v2';
import { AddRoomFacilityCategoryDto, UpdateRoomFacilityCategoryDto } from '@libs/dto';

const url = '/admin/rooms/settings/room-facility-categories';

export const roomFacilityCategoryService = {
  list({ page = '1', per_page = '10' }: { page: string; per_page: string }) {
    return axiosInstance.get(`${url}?page=${page || '1'}&per_page=${per_page || '10'}`);
  },
  create(data: AddRoomFacilityCategoryDto) {
    return axiosInstance.post(`${url}`, data);
  },
  update(id: string, data: UpdateRoomFacilityCategoryDto) {
    return axiosInstance.put(`${url}/${id}`, data);
  },
  delete(id: string) {
    return axiosInstance.delete(`${url}/${id}`);
  },
};

export type RoomFacilityCategoryService = typeof roomFacilityCategoryService;
