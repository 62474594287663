import { axiosInstance } from '@config/axios-instance-v2';
import { FoodCategoriesAddDto, FoodCategoriesListDto, FoodCategoriesUpdateDto } from '@libs/dto/food-categories';

const url = `/admin/hotels`;

export const foodCategoriesService = {
  async list(hotel_id: string, { page, per_page }: { page: string; per_page: string }) {
    const response: FoodCategoriesListDto = await axiosInstance.get(`${url}/${hotel_id}/foods/settings/categories`, {
      params: { page, per_page },
    });
    return response;
  },
  async create(hotel_id: string, data: FoodCategoriesAddDto) {
    const response = await axiosInstance.post(`${url}/${hotel_id}/foods/settings/categories`, data);
    return response;
  },
  async update(hotel_id: string, id: string, data: FoodCategoriesUpdateDto) {
    const response = await axiosInstance.put(`${url}/${hotel_id}/foods/settings/categories/${id}`, data);
    return response;
  },
  async delete(hotel_id: string, id: string) {
    const response = await axiosInstance.delete(`${url}/${hotel_id}/foods/settings/categories/${id}`);
    return response;
  },
};
export type FoodCategoriesService = typeof foodCategoriesService;
