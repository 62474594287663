export enum ActionsEnum {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  GIVE = 'give',
  MANAGE = 'manage', // alias for (READ & CREATE & UPDATE & DELETE)
}

export enum SubjectsEnum {
  ALL = 'all',

  ROLE = 'role',

  ADMIN = 'admin',

  HOTEL = 'hotel',
  HOTEL_DASHBOARD = 'hotel_dashboard',

  CITY = 'city',

  GUEST = 'guest',
  GUEST_WALLET = 'guest_wallet',

  MEMBERSHIP = 'membership',

  ROOM = 'room',
  ROOM_DASHBOARD = 'room_dashboard',
  ROOM_AVAILABILITY = 'room_availability',
  ROOM_RATE = 'room_rate',
  ROOM_ORDER = 'room_order',

  RATE_PLAN = 'rate_plan',

  SMART_PRICING = 'smart_pricing',

  FOOD = 'food',
  FOOD_DASHBOARD = 'food_dashboard',
  FOOD_ORDER = 'food_order',
  FOOD_PAYMENT = 'food_payment',

  SOUVENIR = 'souvenir',
  SOUVENIR_DASHBOARD = 'souvenir_dashboard',
  SOUVENIR_ORDER = 'souvenir_order',
  SOUVENIR_PAYMENT = 'souvenir_payment',

  BALANCE = 'balance',
  BALANCE_WITHDRAW = 'balance_withdraw',
  BALANCE_TOPUP = 'balance_topup',

  BUSINESS_MODEL = 'business_model',

  PROMO_CODE = 'promo_code',
  VOUCHER = 'voucher',
  REFERRAL = 'referral',
  POINT = 'point',
  POINT_GIFT = 'point_gift',
  POINT_HISTORY = 'point_history',
  BANNER = 'banner',

  PAYMENT = 'payment',
  PAYMENT_GATEWAY = 'payment_gateway',
  PAYMENT_CHANNEL = 'payment_channel',
  WALLET = 'wallet',

  IMAGE = 'image',

  CMS = 'cms',

  ACTIVITY_LOG = 'activity_log',

  BANK_ACCOUNT = 'bank_account',
}
