/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Layout, Spin, Row } from 'antd';
import { useNotif } from '@hooks/use-notif';
import { useHistory } from 'react-router-dom';
import { useAuth } from './auth-context';

interface AppContextState {
  selectedHotelName: string;
  selectedHotelId: string;
  selectedHotel?: any;
  isLoading: boolean;
}

export interface AppContextValue {
  selectedHotelName?: string;
  selectedHotelId?: string;
  selectedHotel?: any;
  selectHotel: (hotelId: string, hotelName: string, hotel: any) => void;
  isLoading: boolean;
}

const Spinner = () => (
  <Layout style={{ placeContent: 'center', height: '100vh' }}>
    <Row align="middle" justify="center">
      <Spin size="large" />
    </Row>
  </Layout>
);

const AppContext = React.createContext<AppContextValue>({} as never);

export default function AppContextProvider({ children }) {
  const { identity } = useAuth();
  const history = useHistory();

  const [{ isLoading, selectedHotelId, selectedHotel, selectedHotelName }, updateState] = useState<AppContextState>({
    isLoading: false,
    selectedHotelId: localStorage.getItem('selectedHotelId'),
    selectedHotelName: localStorage.getItem('selectedHotelName'),
    selectedHotel: JSON.parse(localStorage.getItem('selectedHotel')),
  });
  const { addError } = useNotif();

  const setLoading = (state: boolean) => {
    updateState((prevState) => ({
      ...prevState,
      isLoading: state,
    }));
  };

  const selectHotel = (hotelId: string, hotelName: string, hotel: any) => {
    try {
      if (hotelId && hotelName && hotel) {
        localStorage.setItem('selectedHotelName', hotelName);
        localStorage.setItem('selectedHotelId', hotelId);
        localStorage.setItem('selectedHotel', JSON.stringify(hotel));
      } else {
        localStorage.removeItem('selectedHotelId');
        localStorage.removeItem('selectedHotelName');
        localStorage.removeItem('selectedHotel');
      }
      setLoading(true);

      updateState((prevState) => ({
        ...prevState,
        selectedHotelId: hotelId,
        selectedHotel: hotel,
        selectedHotelName: hotelName,
      }));
    } catch (error) {
      addError(error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const savedHotelId: string = localStorage.getItem('selectedHotelId');
    const savedHotelName: string = localStorage.getItem('selectedHotelName');
    const savedHotel: any = JSON.parse(localStorage.getItem('selectedHotel'));
    try {
      setLoading(true);

      if (window.location.pathname === '/') {
        if (savedHotelId) {
          history.push(`/hotels/${savedHotelId}/dashboard`);
        } else {
          history.push(`/dashboard`);
        }
      }

      if (identity) {
        if (identity.isSuperAdmin) {
          selectHotel(savedHotelId, savedHotelName, savedHotel);
        }
      }
    } catch (error) {
      addError(error);
      throw new Error(error);
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [identity]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <AppContext.Provider
      value={{
        isLoading,
        selectedHotelId,
        selectedHotel,
        selectedHotelName,
        selectHotel,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useApp = () => React.useContext(AppContext);
