import { axiosInstance } from '@config/axios-instance-v2';
import { ReportFoodRevenueDto, ReportFoodTopTransactionDto } from '@libs/dto';

const url = '/admin';

export const dashboardFoodService = {
  async getTotalRevenue(selectedHotelId, { startDate, endDate }: { startDate: string; endDate: string }) {
    if (selectedHotelId) {
      const response: ReportFoodRevenueDto = await axiosInstance.get(
        `${url}/hotels/${selectedHotelId}/foods/reports/total-revenue`,
        {
          params: {
            startDate,
            endDate,
          },
        }
      );
      return response;
    } else {
      const response: ReportFoodRevenueDto = await axiosInstance.get(`${url}/foods/reports/total-revenue`, {
        params: {
          startDate,
          endDate,
        },
      });
      return response;
    }
  },
  async getInHotelRevenue(
    selectedHotelId,
    { startDate, endDate, deliveryMethod = 'INHOTEL' }: { startDate: string; endDate: string; deliveryMethod: string }
  ) {
    if (selectedHotelId) {
      const response: ReportFoodRevenueDto = await axiosInstance.get(
        `${url}/hotels/${selectedHotelId}/foods/reports/total-revenue`,
        {
          params: {
            startDate,
            endDate,
            deliveryMethod,
          },
        }
      );
      return response;
    } else {
      const response: ReportFoodRevenueDto = await axiosInstance.get(`${url}/foods/reports/total-revenue`, {
        params: {
          startDate,
          endDate,
          deliveryMethod,
        },
      });
      return response;
    }
  },
  async getDeliveryRevenue(
    selectedHotelId,
    { startDate, endDate, deliveryMethod = 'DELIVERY' }: { startDate: string; endDate: string; deliveryMethod: string }
  ) {
    if (selectedHotelId) {
      const response: ReportFoodRevenueDto = await axiosInstance.get(
        `${url}/hotels/${selectedHotelId}/foods/reports/total-revenue`,
        {
          params: {
            startDate,
            endDate,
            deliveryMethod,
          },
        }
      );
      return response;
    } else {
      const response: ReportFoodRevenueDto = await axiosInstance.get(`${url}/foods/reports/total-revenue`, {
        params: {
          startDate,
          endDate,
          deliveryMethod,
        },
      });
      return response;
    }
  },
  async getTopTransaction(
    selectedHotelId,
    topBy,
    {
      startDate = '',
      endDate = '',
      orderBy = 'desc',
      sortBy = 'totalPrice',
    }: { startDate: string; endDate: string; orderBy: string; sortBy: string }
  ) {
    if (selectedHotelId) {
      const response: ReportFoodTopTransactionDto = await axiosInstance.get(
        `${url}/hotels/${selectedHotelId}/foods/reports/top-transaction`,
        {
          params: {
            startDate,
            endDate,
            orderBy,
            sortBy,
          },
        }
      );
      return response;
    } else {
      const response: ReportFoodTopTransactionDto = await axiosInstance.get(
        `${url}/foods/reports/top-transaction/${topBy}`,
        {
          params: {
            startDate,
            endDate,
            orderBy,
            sortBy,
          },
        }
      );
      return response;
    }
  },
};

export type DashboardFoodService = typeof dashboardFoodService;
