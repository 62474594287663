import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { useApp } from '@context/app-context';
import { useAuth } from '@context/auth-context';
import { Avatar, Layout, Menu } from 'antd';
import React from 'react';
import ChangePassword from './ChangePassword';
import './Header.scss';

const { SubMenu, Item } = Menu;
export default function Header() {
  const { signOut, identity } = useAuth();
  const [visible, setVisible] = React.useState(false);
  const { selectedHotelId, selectedHotelName } = useApp();

  const handleClickMenu = (e) => {
    // eslint-disable-next-line no-unused-expressions
    e.key === 'SignOut' && signOut();

    // eslint-disable-next-line no-unused-expressions
    e.key === 'ChangePassword' && setVisible(true);
  };

  return (
    <Layout.Header className={selectedHotelId ? 'site-layout-background-hotel' : 'site-layout-background'}>
      {selectedHotelId && <div className="hotel-name">{selectedHotelName}</div>}
      <div className="right-container">
        <Menu key="user" mode="horizontal" onClick={handleClickMenu}>
          <SubMenu
            title={
              <span className="right-sub-menu-title">
                <Avatar icon={<UserOutlined />} />
                <span className="user-name">{identity.fullName || 'Guest'}</span>
                <DownOutlined />
              </span>
            }
            className="list-menu-header"
            key="sub-menu"
          >
            <Item key="ChangePassword">
              <p>Change Password</p>
            </Item>
            <Item key="SignOut">
              <p>Sign out</p>
            </Item>
          </SubMenu>
        </Menu>
      </div>

      <ChangePassword visible={visible} setVisible={setVisible} />
    </Layout.Header>
  );
}
