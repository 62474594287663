import { axiosInstance } from '@config/axios-instance-v2';

const url = '/admin/rooms/reports';
const url_hotel = '/admin/hotels';

export const reportHotelService = {
  getPerformance(id: string, startDate: string, endDate: string) {
    return axiosInstance.get(`${url_hotel}/${id}/rooms/reports/performance`, {
      params: {
        startDate,
        endDate,
      },
    });
  },
  getCheckinToday(id: string) {
    return axiosInstance.get(`${url_hotel}/${id}/rooms/reports/checkin-today`);
  },
  getBookingToday(id: string) {
    return axiosInstance.get(`${url_hotel}/${id}/rooms/reports/booking-today`);
  },
  getTotalRevenue(id: string, period: string) {
    return axiosInstance.get(`${url_hotel}/${id}/rooms/reports/total-revenue?period=${period?.toUpperCase()}`);
  },
  getGuestTransaction(id: string, startDate: string, endDate: string, { page = 1, per_page = 10 }) {
    return axiosInstance.get(`${url_hotel}/${id}/rooms/reports/guest-transaction`, {
      params: {
        page,
        per_page,
        startDate,
        endDate,
      },
    });
  },
  getGlobalPerformance(startDate: string, endDate: string) {
    return axiosInstance.get(`${url}/performance?startDate=${startDate}&endDate=${endDate}`);
  },
  getGlobalCheckinToday() {
    return axiosInstance.get(`${url}/checkin-today`);
  },
  getGlobalBookingToday() {
    return axiosInstance.get(`${url}/booking-today`);
  },
  getGlobalTotalRevenue(period: string) {
    return axiosInstance.get(`${url}/total-revenue?period=${period.toUpperCase()}`);
  },
  getGlobalGuestTransaction(startDate: string, endDate: string, { page = 1, per_page = 10 }) {
    return axiosInstance.get(`${url}/guest-transaction`, {
      params: {
        page,
        per_page,
        startDate,
        endDate,
      },
    });
  },
};

export type ReportHotelService = typeof reportHotelService;
