import { axiosInstance } from '@config/axios-instance-v2';
import { ConfigurePaymentChannelDto } from '@libs/dto';

const url = '/admin/payments/channels';

export const paymentChannelService = {
  list() {
    return axiosInstance.get(`${url}?per_page=100`);
  },
  get(gateway: string, channel: string) {
    return axiosInstance.get(`${url}/detail?code=${channel}&gateway=${gateway}`);
  },
  update(data: ConfigurePaymentChannelDto) {
    return axiosInstance.put(`${url}`, data);
  },
};

export type PaymentChannelService = typeof paymentChannelService;
