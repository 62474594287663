export enum UserPermissionEnum {
  SUPERADMIN = 'ADMIN_GANTENG_123',
  GUEST = 'GUEST',

  HOTEL_ALL = 'HOTEL_ALL',
  HOTEL_UPDATE = 'HOTEL_UPDATE',
  HOTEL_READ = 'HOTEL_READ',
  HOTEL_DELETE = 'HOTEL_DELETE',
  ROOM_ALL = 'ROOM_ALL',
  ROOM_UPDATE = 'ROOM_UPDATE',
  ROOM_READ = 'ROOM_READ',
  ROOM_DELETE = 'ROOM_DELETE',
  ROOM_VIEW_ALL = 'ROOM_VIEW_ALL',
  ROOM_VIEW_UPDATE = 'ROOM_VIEW_UPDATE',
  ROOM_VIEW_READ = 'ROOM_VIEW_READ',
  ROOM_VIEW_DELETE = 'ROOM_VIEW_DELETE',
  ROOM_TYPE_ALL = 'ROOM_TYPE_ALL',
  ROOM_TYPE_UPDATE = 'ROOM_TYPE_UPDATE',
  ROOM_TYPE_READ = 'ROOM_TYPE_READ',
  ROOM_TYPE_DELETE = 'ROOM_TYPE_DELETE',
  CITY_ALL = 'CITY_ALL',
  CITY_UPDATE = 'CITY_UPDATE',
  CITY_READ = 'CITY_READ',
  CITY_DELETE = 'CITY_DELETE',
  BED_ALL = 'BED_ALL',
  BED_UPDATE = 'BED_UPDATE',
  BED_READ = 'BED_READ',
  BED_DELETE = 'BED_DELETE',
  BOOKING_CANCEL = 'BOOKING_CANCEL',
  BOOKING_CONFIRM = 'BOOKING_CONFIRM',
  BOOKING_CHECKIN = 'BOOKING_CHECKIN',
  BOOKING_CHECKOUT = 'BOOKING_CHECKOUT',
  BOOKING_READY = 'BOOKING_READY',
}
