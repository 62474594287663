import { axiosInstance } from '@config/axios-instance-v2';
import { AdminUpdateProfile, AddAdminHotelDto } from '@libs/dto/admin';

const url = '/admin/hotels';

export const adminHotelService = {
  async list({
    page = 1,
    per_page = 10,
    selectedHotelId,
  }: {
    page: number;
    per_page: number;
    selectedHotelId: String;
  }) {
    const response: any = await axiosInstance.get(`${url}/${selectedHotelId}/settings/admins`, {
      params: { page, per_page },
    });
    return response;
  },

  create(data: AddAdminHotelDto, selectedHotelId: String) {
    return axiosInstance.post(`${url}/${selectedHotelId}/settings/admins/`, data);
  },
  update(id: string, data: AdminUpdateProfile, selectedHotelId: String) {
    return axiosInstance.put(`${url}/${selectedHotelId}/settings/admins/${id}/`, data);
  },
  delete(id: string, selectedHotelId: String) {
    return axiosInstance.delete(`${url}/${selectedHotelId}/settings/admins/${id}/`);
  },
};

export type AdminHotelService = typeof adminHotelService;
