import { axiosInstance } from '@config/axios-instance-v2';
import { AddCategoriesDto, UpdateCategoriesDto } from '@libs/dto/cms';

const url = '/admin/cms/categories';

export const cmsCategoriesService = {
  list({ page = '1', per_page = '10' }: { page: string; per_page: string }) {
    return axiosInstance.get(`${url}`, {
      params: {
        page,
        per_page,
      },
    });
  },
  get(id: string) {
    return axiosInstance.get(`${url}/${id}`);
  },
  create(data: AddCategoriesDto) {
    return axiosInstance.post(`${url}`, data);
  },
  update(id: string, data: UpdateCategoriesDto) {
    return axiosInstance.put(`${url}/${id}`, data);
  },
  delete(id: string) {
    return axiosInstance.delete(`${url}/${id}`);
  },
};

export type CmsCategoriesService = typeof cmsCategoriesService;
