import BadGateway from '@packages/BadGateway';
import Banners from '@packages/Marketing/Banners';
import AddRatePlan from '@packages/Rooms/Rate/RatePlans/Add';
import EditRatePlan from '@packages/Rooms/Rate/RatePlans/Edit';
import CreateRole from '@packages/Roles/Add';
import UpdateRole from '@packages/Roles/Update';
import React from 'react';
import { RouteComponentProps, RouteChildrenProps } from 'react-router-dom';
const DashboardSuperAdmin = React.lazy(() => import('@packages/Dashboard/SuperAdmin'));
const DashboardAdmin = React.lazy(() => import('@packages/Dashboard/Admin'));
const ListHotel = React.lazy(() => import('@packages/Hotel/List'));
const ListDeletedHotel = React.lazy(() => import('@packages/Hotel/Restore'));
const ListRoomOrder = React.lazy(() => import('@packages/Rooms/RoomOrder/List'));
const ListUser = React.lazy(() => import('@packages/ListUser'));
const ListGroup = React.lazy(() => import('@packages/Membership/List'));
const DetailGroup = React.lazy(() => import('@packages/Membership/DetailGroup'));
const DetailLevel = React.lazy(() => import('@packages/Membership/DetailLevel'));
const SuperAdminSetting = React.lazy(() => import('@packages/Setting/SuperAdmin'));
const AddHotel = React.lazy(() => import('@packages/Hotel/Add'));
const AddHotelManual = React.lazy(() => import('@packages/Hotel/AddManual'));
const AddHotelFacilityCategory = React.lazy(() => import('@packages/HotelFacilityCategory/Add'));
const EditHotelFacilityCategory = React.lazy(() => import('@packages/HotelFacilityCategory/Edit'));
const AddRoomFacilityCategory = React.lazy(() => import('@packages/Rooms/Setting/SuperAdmin/RoomFacilityCategory/Add'));
const EditRoomFacilityCategory = React.lazy(
  () => import('@packages/Rooms/Setting/SuperAdmin/RoomFacilityCategory/Edit')
);
const RoomOrderDetail = React.lazy(() => import('@packages/Rooms/RoomOrder/Detail'));
const FoodSetting = React.lazy(() => import('@packages/Foods/Setting'));
const FoodAdd = React.lazy(() => import('@packages/Foods/Setting/Admin/List/Add'));
const FoodEdit = React.lazy(() => import('@packages/Foods/Setting/Admin/List/Edit'));
const FoodOrderList = React.lazy(() => import('@packages/Foods/FoodOrder/List'));
const FoodOrderDetail = React.lazy(() => import('@packages/Foods/FoodOrder/Detail'));
const AdminHotelSetting = React.lazy(() => import('@packages/Setting/AdminHotel'));
const AddRoom = React.lazy(() => import('@packages/Rooms/Setting/Admin/ListRoom/Add'));
const RoomDetail = React.lazy(() => import('@packages/Rooms/Setting/Admin/ListRoom/Detail'));
const Availability = React.lazy(() => import('@packages/Rooms/Availability'));
const Rate = React.lazy(() => import('@packages/Rooms/Rate'));
const SouvenirsAdd = React.lazy(() => import('@packages/Souvenirs/Setting/Admin/List/Add'));
const SouvenirsEdit = React.lazy(() => import('@packages/Souvenirs/Setting/Admin/List/Edit'));
const SouvenirSetting = React.lazy(() => import('@packages/Souvenirs/Setting'));
const SouvenirCategoriesAdd = React.lazy(() => import('@packages/Souvenirs/Setting/Admin/SouvenirsCategories/Add'));
const SouvenirCategoriesEdit = React.lazy(() => import('@packages/Souvenirs/Setting/Admin/SouvenirsCategories/Edit'));
const SouvenirOrderList = React.lazy(() => import('@packages/Souvenirs/SouvenirOrder/List'));
const SouvenirOrderDetail = React.lazy(() => import('@packages/Souvenirs/SouvenirOrder/Detail'));
const RoomSetting = React.lazy(() => import('@packages/Rooms/Setting'));
const FoodDashboard = React.lazy(() => import('@packages/Foods/Dashboard'));
const SouvenirDashboard = React.lazy(() => import('@packages/Souvenirs/Dashboard'));
const RoomDashboard = React.lazy(() => import('@packages/Rooms/Dashboard'));
const Forbidden = React.lazy(() => import('@packages/Forbidden'));
const Maintenance = React.lazy(() => import('@packages/Maintenance'));
const Cms = React.lazy(() => import('@packages/Cms'));
const CmsMenuAdd = React.lazy(() => import('@packages/Cms/Menus/Add'));
const CmsMenuEdit = React.lazy(() => import('@packages/Cms/Menus/Edit'));
const CmsArticleAdd = React.lazy(() => import('@packages/Cms/Articles/Add'));
const CmsArticleEdit = React.lazy(() => import('@packages/Cms/Articles/Edit'));
const PromoCodeList = React.lazy(() => import('@packages/Marketing/PromoCodes/List'));
const GuestList = React.lazy(() => import('@packages/Guest/ListGuest'));
const GuestDetail = React.lazy(() => import('@packages/Guest/DetailGuest'));
const PromoCodeForm = React.lazy(() => import('@packages/Marketing/PromoCodes/Form'));
const VoucherList = React.lazy(() => import('@packages/Marketing/Vouchers/List'));
const VoucherForm = React.lazy(() => import('@packages/Marketing/Vouchers/Form'));
const PaymentGatewayConfigure = React.lazy(() => import('@packages/PaymentGateways/Configure'));
const PaymentChannelConfigure = React.lazy(() => import('@packages/PaymentChannels/Configure'));
const HotelBalances = React.lazy(() => import('@packages/Marketplace/HotelBalances'));
const DetailHotelBalances = React.lazy(() => import('@packages/Marketplace/HotelBalances/DetailHotelBalances'));
const TopUps = React.lazy(() => import('@packages/Marketplace/TopUps'));
const Referrals = React.lazy(() => import('@packages/Marketplace/Referrals'));
const DetailTopUps = React.lazy(() => import('@packages/Marketplace/TopUps/DetailTopUps'));
const Withdrawals = React.lazy(() => import('@packages/Marketplace/Withdrawals'));
const DetailWithdrawals = React.lazy(() => import('@packages/Marketplace/Withdrawals/DetailWithdrawals'));
const MarketplaceSettings = React.lazy(() => import('@packages/Marketplace/Settings'));
const Balances = React.lazy(() => import('@packages/Balances/Balances'));
const TopUpDetail = React.lazy(() => import('@packages/Balances/TopUpDetail/TopUpDetail'));
const WithdrawalDetail = React.lazy(() => import('@packages/Balances/WithdrawalDetail/WithdrawalDetail'));
const ActivityLogs = React.lazy(() => import('@packages/ActivityLogs/ActivityLogs'));

export interface AppRoutes
  extends Array<{
    id: number;
    component?: React.ComponentType<RouteComponentProps<unknown>> | React.ComponentType<unknown>;
    render?: (props: RouteComponentProps<unknown>) => React.ReactNode;
    children?: ((props: RouteChildrenProps<unknown>) => React.ReactNode) | React.ReactNode;
    path?: string | string[];
    exact?: boolean;
    sensitive?: boolean;
    strict?: boolean;
  }> {}

export const superAdminRoutes: AppRoutes = [
  {
    id: 1,
    path: '/rooms/dashboard',
    component: RoomDashboard,
    exact: true,
  },
  {
    id: 2,
    path: '/hotels',
    component: ListHotel,
    exact: true,
  },
  {
    id: 3,
    path: '/deleted-hotels',
    component: ListDeletedHotel,
    exact: true,
  },
  {
    id: 4,
    path: '/rooms/orders',
    component: ListRoomOrder,
    exact: true,
  },
  {
    id: 5,
    path: '/rooms/settings',
    component: RoomSetting,
    exact: true,
  },
  {
    id: 6,
    path: '/users',
    component: ListUser,
    exact: true,
  },
  {
    id: 7,
    path: '/settings',
    component: SuperAdminSetting,
    exact: true,
  },
  {
    id: 8,
    path: '/add-hotel',
    component: AddHotel,
    exact: true,
  },
  {
    id: 9,
    path: '/add-hotel-manual',
    component: AddHotelManual,
    exact: true,
  },
  {
    id: 10,
    path: '/hotels/:id/rooms/dashboard',
    component: RoomDashboard,
    exact: true,
  },
  {
    id: 11,
    path: '/hotels/:id/rooms/availability',
    component: Availability,
    exact: true,
  },
  {
    id: 12,
    path: '/hotels/:id/rooms/rates',
    component: Rate,
    exact: true,
  },
  {
    id: 13,
    path: '/hotels/:id/rooms/orders',
    component: ListRoomOrder,
    exact: true,
  },
  {
    id: 14,
    path: '/hotels/:id/settings',
    component: AdminHotelSetting,
    exact: true,
  },
  {
    id: 15,
    path: '/rooms/orders/:order_id',
    component: RoomOrderDetail,
    exact: true,
  },
  {
    id: 16,
    path: '/add-hotel-facility-category',
    component: AddHotelFacilityCategory,
    exact: true,
  },
  {
    id: 17,
    path: '/hotel-facility-category/:id',
    component: EditHotelFacilityCategory,
    exact: true,
  },
  {
    id: 18,
    path: '/rooms/settings/add-room-facility-category',
    component: AddRoomFacilityCategory,
    exact: true,
  },
  {
    id: 19,
    path: '/hotels/:id/rooms/settings/add',
    component: AddRoom,
    exact: true,
  },
  {
    id: 20,
    path: '/hotels/:id/rooms/:room_id/settings',
    component: RoomDetail,
    exact: true,
  },
  {
    id: 21,
    path: '/foods/dashboard',
    component: FoodDashboard,
    exact: true,
  },
  {
    id: 22,
    path: '/hotels/:id/foods/dashboard',
    component: FoodDashboard,
    exact: true,
  },
  {
    id: 23,
    path: '/hotels/foods/settings',
    component: FoodSetting,
    exact: true,
  },
  {
    id: 24,
    path: '/foods/orders',
    component: FoodOrderList,
    exact: true,
  },
  {
    id: 25,
    path: '/foods/settings',
    component: FoodSetting,
  },
  {
    id: 26,
    path: '/foods/orders/:food_id',
    component: FoodOrderDetail,
    exact: true,
  },
  {
    id: 27,
    path: '/foods/add',
    component: FoodAdd,
    exact: true,
  },
  {
    id: 28,
    path: '/foods/edit/:id',
    component: FoodEdit,
    exact: true,
  },
  {
    id: 29,
    path: '/hotels/:id/foods/settings',
    component: FoodSetting,
    exact: true,
  },
  {
    id: 30,
    path: '/hotels/:id/foods/add',
    component: FoodAdd,
    exact: true,
  },
  {
    id: 31,
    path: '/hotels/:id/foods/edit/:id_food',
    component: FoodEdit,
  },
  {
    id: 32,
    path: '/hotels/:id/foods/orders',
    component: FoodOrderList,
    exact: true,
  },
  {
    id: 33,
    path: '/hotels/:id/foods/orders/:food_id',
    component: FoodOrderDetail,
    exact: true,
  },
  {
    id: 34,
    path: '/hotels/:id/souvenirs/add',
    component: SouvenirsAdd,
    exact: true,
  },
  {
    id: 35,
    path: '/hotels/:id/souvenirs/edit/:souvenir_id',
    component: SouvenirsEdit,
    exact: true,
  },
  {
    id: 36,
    path: '/souvenirs/settings',
    component: SouvenirSetting,
  },
  {
    id: 37,
    path: '/hotels/:id/souvenirs/settings',
    component: SouvenirSetting,
  },
  {
    id: 38,
    path: '/souvenirs/dashboard',
    component: SouvenirDashboard,
    exact: true,
  },
  {
    id: 39,
    path: '/hotels/:id/souvenirs/dashboard',
    component: SouvenirDashboard,
    exact: true,
  },
  {
    id: 40,
    path: '/souvenirs/categories-add',
    component: SouvenirCategoriesAdd,
    exact: true,
  },
  {
    id: 41,
    path: '/souvenirs/categories-edit/:id',
    component: SouvenirCategoriesEdit,
    exact: true,
  },
  {
    id: 42,
    path: '/souvenirs/orders',
    component: SouvenirOrderList,
    exact: true,
  },
  {
    id: 43,
    path: '/souvenirs/orders/:souvenirs_id',
    component: SouvenirOrderDetail,
    exact: true,
  },
  {
    id: 44,
    path: '/hotels/:id/souvenirs/orders',
    component: SouvenirOrderList,
    exact: true,
  },
  {
    id: 45,
    path: '/hotels/:id/souvenirs/orders/:souvenirs_id',
    component: SouvenirOrderDetail,
    exact: true,
  },
  {
    id: 46,
    path: '/dashboard',
    component: DashboardSuperAdmin,
    exact: true,
  },
  {
    id: 47,
    path: '/hotels/:id/dashboard',
    component: DashboardAdmin,
    exact: true,
  },
  {
    id: 48,
    path: '/hotels/:id/rooms/settings',
    component: RoomSetting,
    exact: true,
  },
  {
    id: 49,
    path: '/hotels/:id/rooms/orders/:order_id',
    component: RoomOrderDetail,
    exact: true,
  },
  {
    id: 50,
    path: '/forbidden',
    component: Forbidden,
    exact: true,
  },
  {
    id: 51,
    path: '/rooms/settings/edit-room-facility-category/:facility_category_id',
    component: EditRoomFacilityCategory,
    exact: true,
  },
  {
    id: 52,
    path: '/bad-gateway',
    component: BadGateway,
    exact: true,
  },
  {
    id: 53,
    path: '/cms',
    component: Cms,
    exact: true,
  },
  {
    id: 54,
    path: '/cms/menus/add',
    component: CmsMenuAdd,
    exact: true,
  },
  {
    id: 55,
    path: '/cms/menus/:id/edit',
    component: CmsMenuEdit,
    exact: true,
  },
  {
    id: 56,
    path: '/cms/articles/add',
    component: CmsArticleAdd,
    exact: true,
  },
  {
    id: 57,
    path: '/cms/articles/:id/edit',
    component: CmsArticleEdit,
    exact: true,
  },
  {
    id: 58,
    path: '/promo-codes',
    component: PromoCodeList,
    exact: true,
  },
  {
    id: 59,
    path: '/guests',
    component: GuestList,
    exact: true,
  },
  {
    id: 60,
    path: '/membership',
    component: ListGroup,
    exact: true,
  },
  {
    id: 61,
    path: '/membership/:id',
    component: DetailGroup,
    exact: true,
  },
  {
    id: 62,
    path: '/membership/:group_id/level/:level_id',
    component: DetailLevel,
    exact: true,
  },
  {
    id: 63,
    path: `/guests/:guest_id`,
    component: GuestDetail,
    exact: true,
  },
  {
    id: 64,
    path: '/banners',
    component: Banners,
    exact: true,
  },
  {
    id: 65,
    path: '/hotels/:id/promo-codes',
    component: PromoCodeList,
    exact: true,
  },
  {
    id: 66,
    path: '/promo-codes/add',
    component: PromoCodeForm,
    exact: true,
  },
  {
    id: 67,
    path: '/hotels/:id/promo-codes/add',
    component: PromoCodeForm,
    exact: true,
  },
  {
    id: 68,
    path: '/promo-codes/:promo_code_id',
    component: PromoCodeForm,
    exact: true,
  },
  {
    id: 69,
    path: '/hotels/:id/promo-codes/:promo_code_id',
    component: PromoCodeForm,
    exact: true,
  },
  {
    id: 70,
    path: '/settings/payment-gateways/:name',
    component: PaymentGatewayConfigure,
    exact: true,
  },
  {
    id: 71,
    path: '/settings/payment-gateways/:name/payment-channels/:channel',
    component: PaymentChannelConfigure,
    exact: true,
  },
  {
    id: 72,
    path: '/vouchers',
    component: VoucherList,
    exact: true,
  },
  {
    id: 73,
    path: '/vouchers/add',
    component: VoucherForm,
    exact: true,
  },
  {
    id: 74,
    path: '/vouchers/:voucher_id',
    component: VoucherForm,
    exact: true,
  },
  {
    id: 75,
    path: '/marketplace/hotel-balances',
    component: HotelBalances,
    exact: true,
  },
  {
    id: 76,
    path: '/marketplace/hotel-balances/:id',
    component: DetailHotelBalances,
    exact: true,
  },
  {
    id: 77,
    path: '/marketplace/top-ups',
    component: TopUps,
    exact: true,
  },
  {
    id: 78,
    path: '/marketplace/top-ups/:id',
    component: DetailTopUps,
    exact: true,
  },
  {
    id: 79,
    path: '/marketplace/Withdrawals',
    exact: true,
    component: Withdrawals,
  },
  {
    id: 80,
    path: '/marketplace/withdrawals/:id',
    component: DetailWithdrawals,
    exact: true,
  },
  {
    id: 81,
    path: '/marketplace/settings',
    component: MarketplaceSettings,
    exact: true,
  },
  {
    id: 82,
    path: '/marketplace/referrals',
    component: Referrals,
    exact: true,
  },
  {
    id: 83,
    path: '/hotels/:id/balances',
    component: Balances,
    exact: true,
  },
  {
    id: 84,
    path: '/hotels/:id/balances/topup/:id',
    component: TopUpDetail,
    exact: true,
  },
  {
    id: 85,
    path: '/hotels/:id/balances/withdrawal/:id',
    component: WithdrawalDetail,
    exact: true,
  },
  {
    id: 86,
    path: '/activity-logs',
    component: ActivityLogs,
    exact: true,
  },
  {
    id: 89,
    path: '/settings/role-and-permissions/add-role',
    component: CreateRole,
    exact: true,
  },
  {
    id: 87,
    path: '/settings/role-and-permissions/:id',
    component: UpdateRole,
    exact: true,
  },
  {
    id: 88,
    path: '/hotels/:id/rooms/rates/add-rate-plan',
    component: AddRatePlan,
    exact: true,
  },
  {
    id: 89,
    path: '/hotels/:id/rooms/rates/:id',
    component: EditRatePlan,
    exact: true,
  },
  {
    id: 90,
    path: '/maintenance',
    component: Maintenance,
    exact: true,
  },
];
