import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Input, Form, Typography, Divider } from 'antd';
import { useService } from '@hooks/use-service';
import { WEB_SERVICES } from '@services/index';
import Dialog from '@components/Dialog';
import { useNotif } from '@hooks/use-notif';
import './Banners.scss';
import ContentLayout from '@components/common/ContentLayout/ContentLayout';
import ContentHelmet from '@components/common/ContentHelmet';
import UploadPhoto from '@components/UploadPhoto';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { BannersDto, BannersListDto } from '@libs/dto';
import { BannerService } from '@services/hotel-banners';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { permissions } from '@utils/permission';
import { ActionsEnum, SubjectsEnum } from '@libs/enums';

const Banners = () => {
  const [dataEditor, setDataEditor] = useState<string>('');
  const bannersService = useService<BannerService>(WEB_SERVICES.Banners);
  const { addError, addSuccess } = useNotif();
  const [Banners, selectBanners] = useState<BannersDto>(null);
  const [bannersForm] = Form.useForm();

  const location = useLocation();
  const history = useHistory();

  const { page, per_page } = queryString.parse(location.search);
  const urlParams: URLSearchParams = new URLSearchParams(window.location.search);

  const {
    data: listBanners = { data: [], meta: { total_data: null, total_page: null } },
    refetch: refetchListBanners,
    mutate,
  } = bannersService.query<BannersListDto>('list', [{ page, per_page }], {
    withEffect: true,
    hookDeps: [page, per_page],
  });

  useEffect(() => {
    if (typeof Number(page) === 'number' && typeof listBanners?.meta?.total_page === 'number') {
      if (Number(page) > listBanners?.meta?.total_page) {
        urlParams.set('page', listBanners?.meta?.total_page?.toString());
        history.replace(`${history.location.pathname}?${urlParams.toString()}`);
      }
    }
    //eslint-disable-next-line
  }, [listBanners?.meta?.total_page]);

  const [modalFormOpenState, setModalForm] = useState<{
    add: boolean;
    edit: boolean;
    delete: boolean;
  }>({
    add: false,
    edit: false,
    delete: false,
  });

  const closeModalForm = (stateAlias: keyof typeof modalFormOpenState) => {
    setModalForm((ps) => ({
      ...ps,
      [stateAlias]: false,
    }));
  };

  const openModalForm = (stateAlias: keyof typeof modalFormOpenState) => {
    setModalForm((ps) => ({
      ...ps,
      [stateAlias]: true,
    }));
  };

  const [image, setImage] = React.useState({
    id: '',
    url: '',
  });

  useEffect(() => {
    if (image.id) {
      bannersForm.setFieldsValue({
        imageId: image.id,
      });
    }
    // eslint-disable-next-line
  }, [image.id]);

  const handleSelectBanner = (data) => {
    selectBanners(data);
    bannersForm.setFieldsValue({
      title: data.title,
      description: data.description,
      priority: data.priority,
    });
    setDataEditor(data.description);
    setImage({
      id: data.image.id,
      url: data.image.url,
    });
    openModalForm('edit');
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (data) => (
        <Space>
          <Button
            onClick={() => {
              handleSelectBanner(data);
            }}
            type="link"
            icon={<EditOutlined />}
            style={{ padding: 0 }}
            disabled={!permissions(ActionsEnum.UPDATE, SubjectsEnum.BANNER)}
          >
            Edit
          </Button>
          <Divider type="vertical" />
          <Button
            onClick={() => {
              selectBanners(data);
              openModalForm('delete');
            }}
            danger
            type="text"
            icon={<DeleteOutlined />}
            style={{ padding: 0 }}
            disabled={!permissions(ActionsEnum.DELETE, SubjectsEnum.BANNER)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const DialogForm = () => (
    <Form layout="vertical" form={bannersForm}>
      <Form.Item
        label="Banners Title"
        name="title"
        rules={[
          {
            required: true,
            min: 3,
            message: 'Please input banners title min. 3 characters !',
          },
        ]}
      >
        <Input placeholder="Insert Banners Title" />
      </Form.Item>
      <Form.Item
        label="Banners Priority"
        name="priority"
        rules={[
          {
            required: true,

            message: 'Please input banners priority between 1-99 !',
          },
        ]}
      >
        <Input placeholder="Insert Banners priority" type="number" />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            min: 3,
            message: 'Please input description min.3 characthers !',
          },
        ]}
      >
        <CKEditor
          editor={ClassicEditor}
          data={dataEditor || ''}
          onChange={(_event, editor) => {
            const data = editor.getData();
            setDataEditor(data);
            bannersForm.setFieldsValue({
              description: data,
            });
          }}
        />
      </Form.Item>
      <Form.Item label="Icon" name="imageId" rules={[{ required: true, message: 'Please upload your Image' }]}>
        <UploadPhoto
          image={image}
          setImage={setImage}
          setForm={(id) => {
            bannersForm.setFieldsValue({
              imageId: id,
            });
          }}
          information={['*Aspek Rasion 4:3', '*Minimum Size 1200 x 900 (px)']}
          alert
        />
      </Form.Item>
    </Form>
  );

  return (
    <ContentHelmet title="Banners Settings">
      <ContentLayout
        BreadcrumbItems={[
          { href: '/', title: 'Home' },
          { href: window.location.pathname, title: 'Marketing Banners' },
        ]}
      >
        <div className="header-banners">
          <Typography style={{ fontSize: 16, fontWeight: 600 }}>Banners</Typography>
          <Button
            disabled={bannersService.isLoading || !permissions(ActionsEnum.CREATE, SubjectsEnum.BANNER)}
            onClick={() => openModalForm('add')}
            type="primary"
            icon={<PlusOutlined />}
          >
            Add Banner
          </Button>
        </div>
        <Divider style={{ margin: 0 }} />
        <div style={{ padding: 24 }}>
          <Table
            columns={columns}
            dataSource={listBanners.data}
            loading={bannersService.isLoading}
            pagination={{
              current: Number(page || 1),
              total: listBanners?.meta.total_data,
              defaultPageSize: 10,
              pageSize: Number(per_page || 10),
              showSizeChanger: true,
              onShowSizeChange: (_current, pageSize) => {
                urlParams.set('per_page', pageSize.toString());
                history.replace(`${history.location.pathname}?${urlParams.toString()}`);
              },
              onChange: (tablePage) => {
                urlParams.set('page', tablePage.toString());
                history.replace(`${history.location.pathname}?${urlParams.toString()}`);
              },
              pageSizeOptions: ['10', '20', '30', '40', '50'],
            }}
            className="table-banners"
          />
        </div>
        <Dialog
          okButtonProps={{
            loading: bannersService.isLoading,
          }}
          title="Add Banner"
          open={modalFormOpenState.add}
          onOk={() => {
            bannersForm.validateFields().then(async (value) => {
              try {
                await bannersService.query('create', [{ ...value }]);
                closeModalForm('add');
                setDataEditor('');
                addSuccess('Successfully Added Banner');
                bannersForm.resetFields();
                setImage({
                  id: '',
                  url: '',
                });
                selectBanners(null);
                refetchListBanners();
              } catch (error) {
                addError(error.response.data);
              }
            });
          }}
          onCancel={() => {
            selectBanners(null);
            setDataEditor('');
            setImage({
              id: '',
              url: '',
            });
            closeModalForm('add');
            bannersForm.resetFields();
          }}
          okText="Save"
          content={DialogForm()}
        />

        <Dialog
          okButtonProps={{
            loading: bannersService.isLoading,
          }}
          title="Edit Banner"
          open={modalFormOpenState.edit}
          onOk={() => {
            bannersForm.validateFields().then(async (value) => {
              try {
                await bannersService.query('update', [
                  Banners.id,
                  { title: value.title, priority: value.priority, description: value.description, imageId: image.id },
                ]);

                closeModalForm('edit');
                addSuccess('Successfully Update Banner');
                bannersForm.resetFields();
                setImage({
                  id: '',
                  url: '',
                });
                setDataEditor('');
                selectBanners(null);
                refetchListBanners();
              } catch (error) {
                closeModalForm('edit');
              }
            });
          }}
          onCancel={() => {
            selectBanners(null);
            setDataEditor('');
            bannersForm.resetFields();
            setImage({
              id: '',
              url: '',
            });
            closeModalForm('edit');
          }}
          okText="Save"
          content={DialogForm()}
        />
        <Dialog
          okButtonProps={{
            loading: bannersService.isLoading,
          }}
          title="Delete Banner"
          open={modalFormOpenState.delete}
          onOk={async () => {
            try {
              await bannersService.query('delete', [Banners.id]);
              closeModalForm('delete');

              addSuccess(`Successfully Delete Banner ${Banners.title}`);
              setDataEditor('');
              setImage({
                id: '',
                url: '',
              });
              bannersForm.resetFields();
              mutate({ ...listBanners, data: listBanners.data.filter((b) => b.id !== Banners.id) });

              selectBanners(null);
              refetchListBanners();
            } catch (error) {
              closeModalForm('delete');
              addError(error.response.data);
            }
          }}
          onCancel={() => {
            closeModalForm('delete');
            setDataEditor('');
            setImage({
              id: '',
              url: '',
            });
            selectBanners(null);
            bannersForm.resetFields();
          }}
          content={
            <p>
              Are you sure to delete <span style={{ fontWeight: 'bold' }}>{Banners && Banners.title}</span>?
            </p>
          }
        />
      </ContentLayout>
    </ContentHelmet>
  );
};

export default Banners;
