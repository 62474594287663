import { axiosInstance } from '@config/axios-instance-v2';
import { PaymentConfigureRoomDto } from '@libs/dto';
import {
  AddRoomImageDto,
  DeleteRoomImageDto,
  PaymentConfigureRoomPayloadDto,
  RoomAddDto,
  RoomBedWithBedID,
  RoomPropertyFacilitiesDto,
  RoomUpdateDto,
  UpdateRoomFacilitiesDto,
  UpdateRoomImageDto,
} from '@libs/dto/room';

const url = `/admin/hotels`;
const urlTenant = '/admin/tenant';

export const roomService = {
  list(selectedHotelId: number, { page = '1', per_page = '10' }: { page: string; per_page: string }) {
    return axiosInstance.get(`${url}/${selectedHotelId}/rooms/settings/rooms`, {
      params: {
        page,
        per_page,
      },
    });
  },
  get(selectedHotelId: number, id: string) {
    return axiosInstance.get(`${url}/${selectedHotelId}/rooms/settings/rooms/${id}`);
  },
  create(selectedHotelId: number, data: RoomAddDto) {
    return axiosInstance.post(`${url}/${selectedHotelId}/rooms/settings/rooms`, data);
  },
  delete(selectedHotelId: number, id: string) {
    return axiosInstance.delete(`${url}/${selectedHotelId}/rooms/settings/rooms/${id}`);
  },
  getRoomProfile(selectedHotelId: number, id: string) {
    return axiosInstance.get(`${url}/${selectedHotelId}/rooms/settings/rooms/${id}/profile`);
  },
  updateRoomProfile(selectedHotelId: number, id: string, data: RoomUpdateDto) {
    return axiosInstance.put(`${url}/${selectedHotelId}/rooms/settings/rooms/${id}/profile`, data);
  },
  listRoomBed(
    selectedHotelId: number,
    id: string,
    { page = '1', per_page = '10' }: { page: string; per_page: string }
  ) {
    return axiosInstance.get(`${url}/${selectedHotelId}/rooms/settings/rooms/${id}/beds`, {
      params: {
        page,
        per_page,
      },
    });
  },
  updateListRoomBed(selectedHotelId: number, id: string, data: RoomBedWithBedID[]) {
    return axiosInstance.put(`${url}/${selectedHotelId}/rooms/settings/rooms/${id}/beds`, data);
  },
  listRoomImage(selectedHotelId: number, id: string) {
    return axiosInstance.get(`${url}/${selectedHotelId}/rooms/settings/rooms/${id}/images`);
  },
  createRoomImage(selectedHotelId: number, id: string, data: AddRoomImageDto) {
    return axiosInstance.post(`${url}/${selectedHotelId}/rooms/settings/rooms/${id}/images`, data);
  },
  updateRoomImagePriority(selectedHotelId: number, id: string, data: UpdateRoomImageDto) {
    return axiosInstance.put(`${url}/${selectedHotelId}/rooms/settings/rooms/${id}/images`, data);
  },
  deleteRoomImage(selectedHotelId: number, id: string, data: DeleteRoomImageDto) {
    return axiosInstance.delete(`${url}/${selectedHotelId}/rooms/settings/rooms/${id}/images?images=[${data}]`);
  },
  async listRoomFacilities(selectedHotelId: number, id: string) {
    const response: RoomPropertyFacilitiesDto = await axiosInstance.get(
      `${url}/${selectedHotelId}/rooms/settings/rooms/${id}/facilities`
    );
    return response;
  },
  async updateRoomFacilities(selectedHotelId: string, id: string, data: UpdateRoomFacilitiesDto) {
    const response = await axiosInstance.put(`${url}/${selectedHotelId}/rooms/settings/rooms/${id}/facilities`, data);
    return response;
  },
  async getPaymentConfigureRoom() {
    const response: PaymentConfigureRoomDto = await axiosInstance.get(`${urlTenant}/payment-configuration`);
    return response;
  },
  async updatePaymentConfigureRoom(body: PaymentConfigureRoomPayloadDto) {
    const response = await axiosInstance.put(`${urlTenant}/payment-configuration`, body);
    return response;
  },
};

export type RoomService = typeof roomService;
