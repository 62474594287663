// import { AddCityDto, UpdateCityDto } from '@libs/dto/city';
import { axiosInstance } from '@config/axios-instance-v2';
import { FoodCategoriesAddDto } from '@libs/dto';
import { SouvenirCategoryListDto } from '@libs/dto/souvenir-category';

const url = '/admin/hotels';
export const souvenirCategoriesService = {
  async list(hotelId: string, { page, per_page }: { page: string; per_page: string }) {
    const response: SouvenirCategoryListDto = await axiosInstance.get(
      `${url}/${hotelId}/souvenirs/settings/categories`,
      {
        params: { page, per_page },
      }
    );
    return response;
  },
  create(id: string, data: FoodCategoriesAddDto) {
    return axiosInstance.post(`${url}/${id}/souvenirs/settings/categories`, data);
  },
  update(id: string, categoryId: string, data: FoodCategoriesAddDto) {
    return axiosInstance.put(`${url}/${id}/souvenirs/settings/categories/${categoryId}`, data);
  },
  delete(id: string, categoryId: string) {
    return axiosInstance.delete(`${url}/${id}/souvenirs/settings/categories/${categoryId}`);
  },
};

export type SouvenirCategoriesService = typeof souvenirCategoriesService;
